import { codable } from './Codable'
import ModelError from './ModelError'

@codable
export default class Site {

  constructor(
    public id: number,
    public name: string,
    public topLatitude: number,
    public bottomLatitude: number,
    public leftLongitude: number,
    public rightLongitude: number,
    public ServiceId: number,
  ) {  }

  static decode(data: any) {
    const {id, name, topLatitude, bottomLatitude, leftLongitude, rightLongitude, ServiceId } = data
    if (typeof(id) !== 'number') { throw new ModelError('id not found while decoding Vehicle!')}
    if (typeof(name) !== 'string') { throw new ModelError('name not found while decoding Vehicle!')}
    if (typeof(topLatitude) !== 'number') { throw new ModelError('topLatitude not found while decoding Vehicle!')}
    if (typeof(bottomLatitude) !== 'number') { throw new ModelError('bottomLatitude not found while decoding Vehicle!')}
    if (typeof(leftLongitude) !== 'number') { throw new ModelError('leftLongitude not found while decoding Vehicle!')}
    if (typeof(rightLongitude) !== 'number') { throw new ModelError('rightLongitude not found while decoding Vehicle!')}
    if (typeof(ServiceId) !== 'number') { throw new ModelError('ServiceId not found while decoding Vehicle!')}

    return new Site(id, name, topLatitude, bottomLatitude, leftLongitude, rightLongitude, ServiceId)

  }
}
