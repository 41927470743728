import styled from 'styled-components'
import colors from '../colors'

interface HeaderProps {
  light?: boolean
}

export default styled.h1<HeaderProps>`
  color: ${props => props.light ? colors.textLight : colors.textDark};
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: left;
`
