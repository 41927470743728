export default class BaseUrl {
  static shared = new BaseUrl()

  url: string

  private constructor() {
    this.url = ''
  }

  getBaseUrl() {
    return this.url
  }
} 