import React from 'react'
import styled from 'styled-components'

interface IProps {
  title: string
  value: any
}

const VehicleDetailMetric = ({title, value}: IProps) => {
  return (
    <Metric>
      <MetricTitle>{title}</MetricTitle>
      <MetricValue>{value || ''}</MetricValue>
    </Metric>
  )
}

export default VehicleDetailMetric

const Metric = styled.div`
  display: flex;
  flex-direction column;
  align-items: center;
  flex: 0 0 33%;
  padding: 0.5rem 0 0.5rem 0;
`

const MetricTitle = styled.div`
  font-family: helveticaneuemedium;
  padding: 0 0 0.5rem 0;
  text-align: center;
`

const MetricValue = styled.div`
  font-family: helveticaneuelight;
`
