import React, {useCallback} from 'react'
import './App.css'
import styled from 'styled-components'
import AppFonts from './fonts'
import Menu from './components/Menu'
import Map from './components/Map'
import Login from './components/Login'
import Statistics from './components/Statistics'
import LastUpdate from './components/LastUpdate'
import Aside from './components/Aside'
import AppLoader from './components/MainLoader'
import MobileFooterMenu from './components/MobileFooterMenu'
import RequestHeaders from './adapters/RequestHeaders'
import BaseUrl from './adapters/BaseUrl'
import {isMobileDevice, respondTo} from './helpers'
import {ReactComponent as RightArrow} from './assets/right_arrow.svg'
import {AppContext} from './AppContext'
import {useAlert} from 'react-alert'

interface MenuProps {
  open: number // use numbers. Boolean type is causing react errors w/styled components https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
}

interface ArrowProps {
  hide: boolean
}


const locateOptions = {
  enableHighAccuracy:false,timeout:10000,maximumAge:0
}

function App() {
  const {state} = React.useContext(AppContext)

  const alert:any = useAlert()

  const [appHasUserData, setAppHasUserData] = React.useState(false)
  const [appMissingUser, setAppMissingUser] = React.useState(false)
  const [panelOpen, setPanelOpen] = React.useState(true)
  const [locationAlertShown, setLocationAlertShown] = React.useState(false)
  const [userPosition, setUserPosition] = React.useState<LatLng|null>(null)

  const {activeMarker} = state

  const handleLocateUserSuccess = React.useCallback((sucess: GeolocationPosition) => {
    const {latitude, longitude} = sucess.coords
    setUserPosition({lat: latitude, lng: longitude})
  }, [])

  const handleLocateUserError = React.useCallback((err: GeolocationPositionError) => {
    console.error('Geolocation error:', err)
    if(err.code === err.PERMISSION_DENIED && !locationAlertShown) {
      setLocationAlertShown(true)
      alert.show('Please allow geolocation access in your browser settings to see your position in the map.', {title: 'Error', closeText: 'OK'})
    }
  }, [alert, locationAlertShown])

  const checkLocation = useCallback(() => {
    if (appHasUserData) {
      if ("geolocation" in navigator) {
        if(isMobileDevice()) {
          navigator.geolocation.watchPosition(
            handleLocateUserSuccess,
            handleLocateUserError,
            locateOptions);
        } else {
          navigator.geolocation.getCurrentPosition(
            handleLocateUserSuccess,
            handleLocateUserError,
            locateOptions);
        }
      } else {
        alert.show('Browser geolocation service is disabled, please allow your browser to access location service', {title: 'Error', closeCopy: 'OK'})
      }
    }
  }, [alert, appHasUserData, handleLocateUserError, handleLocateUserSuccess])

  const openPanel = () => {
    setPanelOpen(true)
  }

  const closePanel = () => {
    setPanelOpen(false)
  }

  const handleArrowClick = () => {
    if (panelOpen) {
      closePanel()
    } else {
      openPanel()
    }
  }

  const panelIsOpen = React.useMemo(() => panelOpen ? 1 : 0, [panelOpen])

  const detailsOpen = React.useMemo(() => activeMarker !== null, [activeMarker])

  const checkLocalStorage = useCallback(() => {
    const expire: string | null = localStorage.getItem('expire')
    const token: string | null = localStorage.getItem('token')
    const serviceToken: string | null = localStorage.getItem('serviceToken')
    // const site: string | null = localStorage.getItem('site')
    const baseUrl: string | null = localStorage.getItem('baseUrl')

    const allDataAvailable =
      expire !== null && token !== null && serviceToken !== null && baseUrl !== null
  
    if (allDataAvailable) {
      const userLoggedIn = token && expire && Number(expire) > new Date().getTime()
      if (userLoggedIn) {
        RequestHeaders.shared.authorization = 'Bearer ' + token
        RequestHeaders.shared.serviceToken = serviceToken || ''
        BaseUrl.shared.url = baseUrl || ''
    
        setAppHasUserData(true)
      } else {
        setAppMissingUser(true)
      }
    } else {
      setAppMissingUser(true)
    }
  }, [])

  React.useEffect(() => {
    checkLocalStorage()
    checkLocation()

    if (activeMarker !== null && !panelIsOpen) {
      setPanelOpen(true)
    }
  }, [
    checkLocalStorage,
    checkLocation,
    activeMarker,
    panelIsOpen
  ])

  return (
    <div className="App">
      <AppFonts />
      {
        appHasUserData
        ? (
          <>
            <header>
              <Menu />
            </header>
            <main>
              <MainContent>
                <Map panelIsOpen={panelIsOpen} userPosition={userPosition} />
                <Aside
                  open={panelIsOpen}
                  closePanel={closePanel}
                  openPanel={openPanel}
                  userPosition={userPosition}
                  />
                <ArrowPosition hide={detailsOpen} className="hide-xs hide-sm hide-md">
                  <ArrowImg open={panelIsOpen} onClick={handleArrowClick} />
                </ArrowPosition>
              </MainContent>
              <LastUpdate />
              <Statistics />
              <MobileFooterMenu userPosition={userPosition} />
            </main>
          </>
        )
        : 
        appMissingUser 
          ?  (
            <Login checkStorage={checkLocalStorage} />
          )
          : (
            <AppLoader />
          )
      }
    </div>
  )
}

export default App

const MainContent = styled.div`
  height: 88vh;
  width: 100%;
  position: relative;

  ${respondTo.xs`
    height: calc(100vh - 6rem);
  `}

  ${respondTo.md`
    height: 80vh;
  `}
`

const ArrowImg = styled(RightArrow)`
  width: 1.25rem;
  height: auto;
  cursor: pointer;
  transition: transform 0.5s linear;
  transform: rotateY(${(props: MenuProps) => (props.open ? '0deg' : '180deg')});
`

const ArrowPosition = styled.div`
  display: ${(props: ArrowProps) => (props.hide ? 'none' : 'block')};
  padding: 0 0.5rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
`
