import { codable } from './Codable'
import ModelError from './ModelError'
import SiteModel from './Site'
import { isNil, isEmpty } from 'ramda'

@codable
export default class Service {

  constructor(
    public id: number,
    public name: string,
    public domain: string,
    public token: string,
    public sites: SiteModel[]
  ) {  }

  static decode(data: any) {
    const {id, name, domain, token, Sites } = data
    if (typeof(id) !== 'number') { throw new ModelError('id not found while decoding Service!')}
    if (typeof(name) !== 'string') { throw new ModelError('name not found while decoding Service!')}
    if (typeof(domain) !== 'string') { throw new ModelError('domain not found while decoding Service!')}
    if (typeof(token) !== 'string') { throw new ModelError('token not found while decoding Service!')}
    if (typeof(Sites) !== 'object') { throw new ModelError('Sites not found while decoding Service!')}

    const sitesObj = isNil(Sites) || isEmpty(Sites)
    ? null
    : Sites.map((site: any) => SiteModel.decode(site))

    return new Service(id, name, domain, token, sitesObj)
  }
}
