import React from 'react'
import styled from 'styled-components'
import {MapIcons} from '../assets'
import Site from '../model/Site'
import colors from '../colors'

interface IProps extends SiteNameProps {
  site: Site|null
  onClick: (site: Site|null) => void
}

type SiteNameProps = {
  active: boolean
}

const getIcon = (id?: number) => {
  switch (id) {
    case 2:
      return MapIcons.rome
    case 3:
      return MapIcons.madrid
    case 36:
      return MapIcons.mexicoCity
    default:
      return MapIcons.defaultPointer
  }
}

const SiteMenuIcon = ({site, onClick, active}: IProps) => {
  const handleClick = () => {
    onClick(site)
  }

  return (
    <Wrapper onClick={handleClick}>
      <Icon src={getIcon(site?.id)} />
      <SiteName active={active}>{site === null ? 'All Cities' : site?.name }</SiteName>
    </Wrapper>
  )
}

export default React.memo(SiteMenuIcon)

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`

const Icon = styled.img`
  width: 14px;
  height: auto;
  padding-right: 10px;
`

const SiteName = styled.span`
  color: ${(props: SiteNameProps) =>
    props.active ? colors.activeLink : colors.textDefault};
  font-family: helveticaneuelight;
`
