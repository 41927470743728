 // NOTICE: how string ends without "/" (slash)
 export const DefaultBaseUrl = 'https://core.2hire.io'
 export const batteryRanges:TBatteryRanges = {
   healthyBattery: [80, 100],
   mediumBattery: [50, 79],
   lowBattery: [16, 49],
   criticalBattery: [0, 15]
 }

 type TBatteryRanges = {
   healthyBattery: [number, number]
   mediumBattery: [number, number]
   lowBattery: [number, number]
   criticalBattery: [number, number]
 }

 export const appRefreshRate = 300000 // 5 mins