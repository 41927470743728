import { createGlobalStyle } from 'styled-components'

import HelveticaNeueMedium from './HelveticaNeueMedium.woff'
import HelveticaNeueMedium2 from './HelveticaNeueMedium.woff2'
import HelveticaNeueLight from './HelveticaNeueLight.woff'
import HelveticaNeueLight2 from './HelveticaNeueLight.woff2'
import HelveticaNeueBold from './HelveticaNeueBold.woff'
import HelveticaNeueBold2 from './HelveticaNeueBold.woff2'

export default createGlobalStyle`

    @font-face {
        font-family: 'helveticaneuebold';
        src: url(${HelveticaNeueBold}) format('woff2'),
            url(${HelveticaNeueBold2}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'helveticaneuelight';
        src: url(${HelveticaNeueLight2}) format('woff2'),
            url(${HelveticaNeueLight}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'helveticaneuemedium';
        src: url(${HelveticaNeueMedium2}) format('woff2'),
            url(${HelveticaNeueMedium}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

`;

