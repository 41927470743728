import React from 'react'
import Vehicle, {VehicleTypes, DeviceType} from '../model/Vehicle'
import colors from '../colors'
import DetailButton from './DetailButton'
import MapAdapter, {VehicleCommand} from '../adapters/MapAdapter'
import {AppContext} from '../AppContext'
import styled, {css} from 'styled-components'
import ItemsCarousel from 'react-items-carousel'
import {respondTo} from '../helpers'
import {useAlert} from 'react-alert'

interface IProps {
  vehicle: Vehicle | null
}

interface DotProps {
  isActive: boolean
}

interface PageItemProps {
  lastItemFill?: boolean
}

const itemsPerPage = 6

const VehicleActions = ({vehicle}: IProps) => {
  const {dispatch} = React.useContext(AppContext)
  const [activeItemIndex, setActiveItemIndex] = React.useState(0)
  const alert:any = useAlert()

  const handleCommand = async (
    command: VehicleCommand,
    type?: VehicleTypes | 'vehicle',
  ) => {
    if (vehicle) {
      return MapAdapter.runVehicleCommand(
        type || vehicle.type,
        vehicle.id,
        command,
      ).then(() => {
        MapAdapter.getVehicle(vehicle.id).then((payload) =>
          dispatch({
            type: 'UPDATE_MARKER',
            payload,
          }),
        )
      }).catch((e) => {
        alert.show(`An error occurred while executing the ${type || 'vehicle'} ${command} command. Please try again.`, {title: 'Error', closeText: 'Go back'})
        return Promise.reject(e)
      })
    }
  }

  const vehicleActionsByVehicleType = React.useCallback(() => {
    switch(vehicle?.type) {
      case VehicleTypes.car:
        return [
          <DetailButton
              key="car-Start"
              title="Start"
              subtitle={vehicle?.type}
              onClick={() => handleCommand('start', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="car-Stop"
              title="Stop"
              subtitle={vehicle?.type}
              onClick={() => handleCommand('stop', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="car-On"
              title="Door"
              subtitle="Unlock"
              disabled={true}
              onClick={() => handleCommand('on', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="car-Off"
              title="Door"
              subtitle="Lock"
              disabled={true}
              onClick={() => handleCommand('off', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
             <DetailButton
              key="car-Locate"
              title="Immobilizer"
              subtitle="ON"
              disabled={true}
              onClick={() => handleCommand('locate', 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="car-Open"
              title="Immobilizer"
              subtitle="OFF"
              disabled={true}
              onClick={() => handleCommand('saddle/open', VehicleTypes.scooter)}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
        ]
      default:
        return [
          <DetailButton
              key="default-Start"
              title="Start"
              subtitle={vehicle?.type === 'scooter' ? 'Moped' : vehicle?.type}
              onClick={() => handleCommand('start', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="default-Stop"
              title="Stop"
              subtitle={vehicle?.type === 'scooter' ? 'Moped' : vehicle?.type}
              onClick={() => handleCommand('stop', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="default-On"
              title="On"
              subtitle="Engine"
              onClick={() => handleCommand('on', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="default-Off"
              title="Off"
              subtitle="Engine"
              onClick={() => handleCommand('off', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
             <DetailButton
              key="default-Locate"
              title="Locate"
              subtitle="Vehicle"
              onClick={() => handleCommand('locate', 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="default-Open"
              title="Open"
              subtitle="Saddle"
              disabled={
                !(
                  vehicle?.deviceType === DeviceType.NIU ||
                  vehicle?.deviceType === DeviceType.PERSONAL
                )
              }
              onClick={() => handleCommand('saddle/open', VehicleTypes.scooter)}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />,
            <DetailButton
              key="default-Open"
              title="Open"
              subtitle="Trunk"
              disabled={vehicle?.type !== VehicleTypes.scooter}
              onClick={() => handleCommand('trunk/open')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
        ]

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.deviceType, vehicle?.type])

  const getPages = () => {
    const content = vehicleActionsByVehicleType()
    return Array(Math.ceil(content.length / itemsPerPage))
      .fill(null)
      .map((el, i) => (
        <Page className="page" key={`${vehicle?.type}-page-${i}`}>
          <PageItems lastItemFill={!(content.length % 2) ? false : i === content.length ? true : false }>
            {content.splice(0, itemsPerPage)}
          </PageItems>
        </Page>
      ))  
  }

  const getSliderDots = () => {
    const content = vehicleActionsByVehicleType()
    const filled =  Array(Math.ceil(content.length / itemsPerPage))
    .fill(null)
    if (filled.length > 1) {
      return filled
        .map((el, indx) => (
          <Dot
            key={`dot-${indx}`}
            isActive={activeItemIndex === indx}
            onClick={() => setActiveItemIndex(indx)}
          />
        ))
    }
    return []
  }

  return (
    <Actions className="Actions">
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        gutter={20}>
        {getPages()}
        {/* <Page className="page">
          <PageItems>
            <DetailButton
              title="Start"
              subtitle={vehicle?.type === 'scooter' ? 'Moped' : vehicle?.type}
              onClick={() => handleCommand('start', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
            <DetailButton
              title="Stop"
              subtitle={vehicle?.type === 'scooter' ? 'Moped' : vehicle?.type}
              onClick={() => handleCommand('stop', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
            <DetailButton
              title="On"
              subtitle="Engine"
              onClick={() => handleCommand('on', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
            <DetailButton
              title="Off"
              subtitle="Engine"
              onClick={() => handleCommand('off', vehicle?.type || 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
             <DetailButton
              title="Locate"
              subtitle="Vehicle"
              onClick={() => handleCommand('locate', 'vehicle')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
            <DetailButton
              title="Open"
              subtitle="Saddle"
              disabled={vehicle?.deviceType !== DeviceType.NIU}
              onClick={() => handleCommand('saddle/open', VehicleTypes.scooter)}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
          </PageItems>
        </Page>
        <Page className="page">
          <PageItems lastItemFill={true}>
            <DetailButton
              title="Open"
              subtitle="Trunk"
              disabled={vehicle?.type !== VehicleTypes.scooter}
              onClick={() => handleCommand('trunk/open')}
              color={colors.activeLink}
              mobileColor={colors.activeLink}
              widthHalf={true}
            />
          </PageItems>
        </Page> */}
      </ItemsCarousel>
      <ItemsFooter>
        {getSliderDots()}
        {/* <Dot
          isActive={activeItemIndex === 0}
          onClick={() => setActiveItemIndex(0)}
        />
        <Dot
          isActive={activeItemIndex === 1}
          onClick={() => setActiveItemIndex(1)}
        /> */}
      </ItemsFooter>
    </Actions>
  )
}

export default VehicleActions

const Actions = styled.div`
  width: 100%;
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
`

const PageItems = styled.div<PageItemProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-evenly;

  &:after {
    ${(props:PageItemProps) => props.lastItemFill ? css`
    content: "";
    flex: 0 0 45%;
    width: 47%;
    ` : ''}
  }
`

const ItemsFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
`

const Dot = styled.div<DotProps>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  background: ${(props) => (props.isActive ? 'gray' : 'lightgray')};

  ${respondTo.md`
    width: 1rem; 
    height: 1rem;
    border-radius: 1rem;
  `}
`
