import {DefaultBaseUrl} from '../AppSettings'
import Service from '../model/Service'
import UserToken from '../model/UserToken'
import serverResponseValidator from './ServerResponseValidator'

export default class LoginAdapter {
  static async getServiceToken(name: string) {
    return LoginAdapter.fetchServiceToken(name, DefaultBaseUrl)
      .then(service => {
        const compareUrl = DefaultBaseUrl.replace(/(^\w+:|^)\/\//, '')
        if (service.domain === compareUrl) {
          return service
        } else {
          return this.fetchServiceToken(name, `https://${service.domain}`)
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  static async fetchServiceToken(name: string, serviceUrl: string) {
    const url = serviceUrl + `/v4/admin/service`

    const serviceTokenHeaders = new Headers({
      'Authorization': 'token',
      'Content-Type': 'application/json'
    });

    const serviceTokenParams = {
      body: JSON.stringify({ name }),
      headers: serviceTokenHeaders,
      method: 'POST',
    };

    return fetch(url, serviceTokenParams)
      .then(response => response.json())
      .then(json =>
        serverResponseValidator<Service>(json, Service),
      )
      .then(d => d as Service)
      .catch(error => {
        return Promise.reject(error)
      })
  }

  static async authenticateUser(baseUrl: string, serviceToken: string, username: string, password: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-SERVICE-TOKEN': serviceToken
    })
    const url = `https://${baseUrl}/v4/admin/login`

    const loginParams = {
      body: JSON.stringify({ username, password }),
      headers,
      method: 'POST',
    }
  
    return fetch(url, loginParams)
    .then(response => response.json())
    .then(json =>
      serverResponseValidator<UserToken>(json, UserToken),
    )
    .then(d => d as UserToken)
    .catch(error => {
      return Promise.reject(error)
    })
  }

}