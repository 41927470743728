import React from 'react'
import MapMarker from './components/MapMarker'
import MapCluster from './components/MapClusterMarker'
import Supercluster from 'supercluster'

interface IProps {
  key: string,
  points: any,
  bounds: any,
  zoom: number,
  onClusterClick: (expansionZoom: number, coords: LatLng) => void,
}

export const getClusters = ({points, bounds, zoom, onClusterClick, key}: IProps) => {
  const superCluster = new Supercluster({ radius: 75, maxZoom: 19})
  superCluster.load(points)
  const zoomInt = Math.round(zoom || 0)

  const clusters = superCluster.getClusters(bounds, zoomInt)

  const handleClusterClick = (clusterId:  number, coords: LatLng) => {
    const expansionZoom = Math.min(
      superCluster.getClusterExpansionZoom(clusterId),
      20
    )
    onClusterClick(expansionZoom, coords) 
  }

  const list = clusters.map((cluster, indx) => {
    const {
      cluster: isCluster,
      point_count: pointCount,
      cluster_id: clusterId,
    } = cluster.properties;

    if (isCluster) {
      const [longitude, latitude] = cluster.geometry.coordinates;
      return (
        <MapCluster
          key={`${zoom}.${clusterId}`}
          count={pointCount}
          online={points[0].properties.vehicle.online}
          batteryLevel={points[0].properties.vehicle.totalPercentage}
          onClick={() => handleClusterClick(clusterId, {lng: longitude, lat: latitude})}
          lat={latitude}
          lng={longitude}
        />
      )
    }

    const {vehicle} = cluster.properties
    return (
      <MapMarker
        key={`${vehicle.id}`}
        lat={vehicle.latitude}
        lng={vehicle.longitude}
        vehicle={vehicle}
      />
    )
  })

  return list 
}
