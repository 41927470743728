import React from "react";
import styled from "styled-components";

interface IProps {
  color: string
  value: string
  description: string
}

const StatisticsSquare = (props: IProps) => (
  <Statistic color={props.color}>
    <ValueWrapper>{props.value}</ValueWrapper>
    <DescriptionWrapper>{props.description}</DescriptionWrapper>
  </Statistic>
);

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.color || "black"};
  width: 12rem;
  height: 10rem;
  border-radius: 0.5rem;
  border: 3px solid;
  border-color: ${props => props.color || "black"};
`;

const ValueWrapper = styled.span`
  font-size: 2rem;
`

const DescriptionWrapper = styled.span`
  font-family: helveticaneuelight;
  font-size: 1.25rem;
  padding-top: 0.5rem;
` 

export default StatisticsSquare