const colors = {
  header: '#222222',
  textLight: '#FFFFFF',
  textLightTitle: '#656565',
  textDark: '#222222',
  textDefault: '#000000',
  activeLink: '#0067FF',
  mobileMenuBackground:'#FFFFFF',
  menuDisabled: '#757575',
  markerActive: '#0AA077',
  markerGoodBattery: '#0AA077',
  markerMedBattery: '#0a50a0',
  markerLowBattery: '#EBB000',
  markerCritical: '#CE0000',
  markerUnavailable: '#AB5E27',
  markerOffline: '#222222',
  markerDefault: '#222222',
  filtersBackground: '#F1F1F1',
  filtersChecked: '#2196F3',
  filtersMarker: '#FFFFFF',
  filtersBorder: 'rgba(131, 131, 131, 0.75)',
  filtersTitle: '#9E9E9E',
  panelBackground: '#FFFFFF',
  panelListIcon: '#C7C7C7',
  inputBackground: '#E5E5E5',
  inputActiveBackground: '#767676',
  inputActiveForeground: '#B3B3B3',
  inputText: '#606060',
  inputIcon: '#000000',
  iconActive: '#A0C437',
  iconBooked: '#DEB30D',
  iconRunning: '#284678',
  iconParked: '#257C85',
  // iconUnavailable: '#5A5A5A',
  iconTrunkIsOpen: '#75a00a',
  iconLost: '#5A5A5A',
  iconMissing: '#757575',
  iconMaintenance: '#AB5E27',
  iconUnLocked: '#a00a0a',
  iconLocked: '#0a73a0',
  iconTrunkOpen: '#B63636',
  iconOnline: '#5BEC20',
  iconOffline: '#797979',
  buttonBackground: '#E6E6E6',
  buttonBackgroundMobile: '#E6E6E6',
  buttonDirections: '#0487EF',
  buttonMaintenance: '#A51819',
  buttonMissing: '#505050',
  buttonLost: '#000000',
  mobileCloseBtn: '#D2D2D2',
  handle: '#c1c1c1',
  callForAttention1: '#E5E5E5', 
  callForAttention2: '#FFFFFF', 
  callForAttention3: '#E5E5E5', 
  callForAttention4: '#FFFFFF', 
  callForAttention5: '#E5E5E5',
  checkboxSelected: '#039be5',
  // statisticsActive: '#029be4',
  loadingIndicator: '#03dbfc',
  loginPanel: '#FFFFFF'
}

export default colors