import React from 'react'
import MapFilterItem from './MapFilterItem'
import {VehicleQuickFilters} from '../adapters/MapAdapter'

const defaultFilters: [VehicleQuickFilters, string][] = [
  ['type.car', 'Cars'],
  ['type.kick', 'Scooters'],
  ['type.scooter', 'Mopeds'],
  ['type.bike', 'Bikes'],
  // ['availability.active', 'Active'],
  ['battery.healthy', 'Healthy'],
  ['battery.low', 'Low batt.'],
  ['battery.medium', 'Med. batt.'],
  ['battery.critical', 'Critical'],
  ['offline', 'Offline'],
  ['lost', 'Lost'],
  ['missing', 'Missing'],
  ['maintenance', 'Maintenance'],
]

export const getMapFilters = () => {
  return MapFilters()
}

const MapFilters: Function = (): JSX.Element[] => {
  return defaultFilters.map((f, i) => (
    <MapFilterItem
      key={`${i}`}
      filterKey={f[0]}
      title={f[1]}
    />
  ))

}

export default MapFilters
