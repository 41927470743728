import { codable } from './Codable'

@codable
export default class ServerError {

    constructor(
        public type: string,
        public description: string,
        public langTag: string
    ) { }

    static decode(data: any) {
        const type = data.type
        if (typeof type !== 'string') { throw Error('ServerError type not found') }
        const description = data.description
        if (typeof description !== 'string') { throw Error('ServerError description not found')}
        const langTag = data.langTag
        if (typeof langTag !== 'string') { throw Error('ServerError langTag not found')}

        return new ServerError(type, description, langTag)
    }
}
