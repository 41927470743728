import { codable } from './Codable'
import ModelError from './ModelError'
import { isEmpty, isNil } from 'ramda'
import {classifyBatteryLevel} from '../helpers'

export enum VehicleStatus {
  free = 'free',
  booked = 'booked',
  running = 'running',
  parked = 'parked',
  unavailable = 'unavailable',
  trunkIsOpen = 'trunkIsOpen',
  unlocked = 'unlocked',
  locked = 'locked',
}

export enum VehicleTypes {
  scooter = 'scooter',
  kick = 'kick',
  car = 'car',
  bike = 'bike',
}

export enum UnavailableDescriptor {
  NONE = 0,
  WAREHOUSE = 1,
  MAINTENANCE = 2,
  TRANSPORTATION = 3,
  WANTED = 4,      // missing
  STOLEN = 5,      // lost
  FAILED_STOP = 6
}

export enum DeviceType {
  PERSONAL = 0,
  NIU = 1,
  SEGWAY = 3,
  CONCOX = 4,
  OMNILOCK = 5
}

export interface ExtraInfo {
  movoUnavailableDescriptor: UnavailableDescriptor
}

type BatteryClassification = 'Healthy'|'Low Battery'| 'Critical' | 'Offline'

@codable
export default class Vehicle {

  constructor(
    public id: number,
    public licensePlate: string,
    public longitude: number,
    public latitude: number,
    public status: VehicleStatus,
    public type: VehicleTypes,
    public registrationDate: string | null,
    public brand: string | null,
    public version: string | null,
    public modelname: string | null,
    public inMaintenance: boolean,
    public online: boolean,
    public km: number,
    public deviceType: DeviceType,
    public totalPercentage: number,
    public referenceCode: string,
    public extraInfo: ExtraInfo,
    public batteryClassification: BatteryClassification,
    public fixtaken: number,
  ) {  }

  static decode(data: any) {
    const {id, license_plate, longitude, latitude, status, type, registration_date, brand, version,
    model_name, inMaintenance, online, km, deviceType, total_percentage, reference_code, extraInfo, fixtaken } = data
    if (typeof(id) !== 'number') { throw new ModelError('id not found while decoding Vehicle!')}
    if (typeof(license_plate) !== 'string') { throw new ModelError('license_plate not found while decoding Vehicle!')}
    if (typeof(longitude) !== 'number') { throw new ModelError('longitude not found while decoding Vehicle!')}
    if (typeof(latitude) !== 'number') { throw new ModelError('latitude not found while decoding Vehicle!')}
    if (typeof(status) !== 'string') { throw new ModelError('status not found while decoding Vehicle!')}
    if (typeof(type) !== 'string') { throw new ModelError('type not found while decoding Vehicle!')}
    if (typeof(registration_date) !== 'string') { throw new ModelError('registration_date not found while decoding Vehicle!')}
    if (typeof(online) !== 'boolean') { throw new ModelError('online not found while decoding Vehicle!')}
    if (typeof(km) !== 'number') { throw new ModelError('km not found while decoding Vehicle!')}
    if (typeof(deviceType) !== 'number') { throw new ModelError('deviceType not found while decoding Vehicle!')}
    if (typeof(total_percentage) !== 'number') { throw new ModelError('total_percentage not found while decoding Vehicle!')}
    if (typeof(reference_code) !== 'string') { throw new ModelError('reference_code not found while decoding Vehicle!')}
    if (typeof(extraInfo) !== 'object') { throw new ModelError('extraInfo not found while decoding Vehicle!')}
    if (typeof(fixtaken) !== 'number') { throw new ModelError('fixtaken not found while decoding Vehicle!')}

    const extraInfoObj = isNil(extraInfo) || isEmpty(extraInfo)
      ? null
      : extraInfo

    const batteryClassification = classifyBatteryLevel(online, total_percentage)

    return new Vehicle(id, license_plate, longitude, latitude, status as VehicleStatus, type as VehicleTypes, registration_date, brand, version,
      model_name, inMaintenance, online, km, deviceType, total_percentage, reference_code, extraInfoObj, batteryClassification, fixtaken)

  }
}
