import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {ReactComponent as Magnifier} from '../assets/magnifier.svg'
// import Caret from '../assets/caret.svg'
import Vehicle from '../model/Vehicle'
import {FilterLocation, IAppContext, StoreProvider, withAppContext} from '../AppContext'
import {appRefreshRate} from '../AppSettings'
import MapAdapter, {VehicleTypedFilters} from '../adapters/MapAdapter'
import MultiSelect from 'react-multiselect-button-dropdown'
import CustomSelect from './CustomSelect'
import './SearchInputs.css'

type FilterInputValue = string|number|boolean|string[]

type OptionLabel = {value: string, label: string}

interface IProps {
  activeSite?: number | null
  updateVehicles: (vehicles: Vehicle[]) => void
  currentFilters: FilterLocation
  appContext: IAppContext
}

interface IState {
  value: FilterInputValue
  multiSelectValue: OptionLabel[]
  activeFilter: VehicleTypedFilters
  filterOptionChanged: boolean
  callForAttention: boolean
  lastUpdateCheck: number
}

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

const mainSelectOptions = [
  {value: 'none', label: 'Filter'},
  {value: 'reference_code', label: 'Ref. Code'},
  {value: 'id', label: 'VID'},
  {value: 'license_plate', label: 'License Plate'},
  {value: 'online', label: 'Online'},
  {value: 'type', label: 'Type'},
  {value: 'deviceType', label: 'Device Type'},
  {value: 'boundingBox', label: 'Bounding Box'},
  {value: 'brand', label: 'Manufacturer'},
  {value: 'model_name', label: 'Model name'},
  {value: 'currentUser', label: 'Current User'},
  {value: 'availability', label: 'Availability'},
  {value: 'total_percentage', label: 'Battery Status'},
  {value: 'external_status', label: 'Vehicle Status'},
  {value: 'status', label: 'Vehicle State'},
]

const availabilitySelectOptions = [
  {value:'active', label: 'Active' },
  {value:'assigned', label: 'Assigned' },
  {value:'inUse', label: 'In Use' },
  {value:'maintenance', label: 'Maintenance' },
]

// component is stateful so we can handle timeout
class SearchInputs extends React.Component<IProps, IState> {
  timer: any = undefined
  inputTypeNoneRef = React.createRef<HTMLInputElement>()

  state = {
    value: '',
    multiSelectValue: [], // used only to track MultiSelect input.
    activeFilter: 'none' as VehicleTypedFilters,
    filterOptionChanged: false, // used to avoid trigger on first render/
    callForAttention: false,
    lastUpdateCheck: 0,
  }


  componentDidCatch() {
    return {}
  }

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {

    clearTimeout(this.timer)

    // online is of input type checkbox
    const value = this.state.activeFilter === 'online' ? evt.target.checked : evt.target.value

    this.setState({ value: this.formatValue(value), callForAttention: false })

    this.timer = setTimeout(this.getVehicles, WAIT_INTERVAL)
  }

  // remove type of selectedOption as library is typed as non dynamic values
  handleMultiSelectChange = (selectedOption: any) => {
    clearTimeout(this.timer)
    const value = Object.keys(selectedOption).reduce((prev: string[], key: string) => {
      if (selectedOption[key]) {
        prev.push(key)
      }
      return prev
    }, [])
    
    if (this.state.filterOptionChanged) {
      // first render of multi select
      this.setState({
        filterOptionChanged: false, 
      })
    } else {
      this.setState({ value: value.length > 0 ? value : [] }, () => {
        this.timer = setTimeout(this.getVehicles, WAIT_INTERVAL)
      })
    }
    
  }

  handleCheckboxInputChange = (value: true|false|'none') => {
    clearTimeout(this.timer)

    this.setState({ value }, () => {
      this.timer = setTimeout(this.getVehicles, WAIT_INTERVAL)
    })
  }

  formatValue = (value: FilterInputValue) => {
    switch(this.state.activeFilter) {
      case 'id':
        return Number(value)
      default:
        return value
    }
  }

  handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = evt.which || evt.charCode || evt.keyCode || 0;
    if (charCode === ENTER_KEY) {
      clearTimeout(this.timer)
      this.getVehicles()
    }
  }

  getQuery = () => {
    const {activeFilter, value} = this.state
    if (value === '') {
      return {}
    } else {
      switch (activeFilter) {
        case 'none':
          return {}
        case 'reference_code':
        case 'id':
        case 'license_plate':
        case 'brand':
        case 'model_name':
        case 'type':
        case 'status':
          return {_self: {[activeFilter]: value}}
        case 'online':
          return this.state.value === 'none' ? {} : {_self: {[activeFilter]: value}}
        case 'deviceType':
          return Array.isArray(value) && value.length > 0 ? {_self: {[activeFilter]: (value as any).map((v: string) => Number(v))}} : {}
        case 'boundingBox':
          return {_self: value as any}
        case 'currentUser':
          return {
            bookings:{
              _self:{
                status:[0,1]},
                user:{
                  _virtual: {
                    fullName: this.state.value
                  }
                }
              }}
        case 'availability':
          switch(value) {
            case 'active':
              return {
                _self: {
                  online: true,
                  status: ['free','booked','unlocked','running','trunkIsOpen','parked','locked'],
                  total_percentage: [[25,100]],
                  inMaintenance:false
                }
              }
            case 'assigned':
              return {bookings:{_self:{status:[0,1]}}}
            case 'inUse':
              return {_self:{status:['booked','unlocked','running','trunkIsOpen','parked','locked']}}
            case 'maintenance':
              return {_self:{inMaintenance:true}}
            default:
              return {}
          }
        case 'total_percentage':
          return Array.isArray(value) ? {_self: {[activeFilter]: (value as any).map((v: string) => JSON.parse(v))}} : {}
        case 'external_status':
          return {_virtual:{[activeFilter]: value}}

        default:
          return {}
      }
    }
  }

  activateUpdateRecursively = () => {

    if (this.props.currentFilters !== FilterLocation.panel) {
      return
    }

    setTimeout(() => {
      if (Date.now() - this.state.lastUpdateCheck > appRefreshRate) {
        this.getVehicles()
      }

    }, 5000)
  }

  setAppLoading = (isLoading: boolean) => {
    this.props.appContext.dispatch({
      type: 'UPDATE_LOADER',
      payload: isLoading,
    })
  }

  updateVehicles = () => {
    this.setState({ lastUpdateCheck: Date.now() }, () => this.activateUpdateRecursively())
    this.setAppLoading(true)
    MapAdapter.getVehiclesTypedFilter(this.props.activeSite, this.getQuery()).then(vehicles => {
      this.setAppLoading(false)
      this.props.updateVehicles(vehicles)
    }).catch(() => {
      this.setAppLoading(false)
    })
  }

  getVehicles = () => { 
    if (this.state.activeFilter !== 'none') {
      this.updateVehicles()
    } else {
      if (this.state.activeFilter === 'none' && this.state.value === '') {
        this.updateVehicles()
      } else {
        this.setState({callForAttention: true})
      }
    }
  }

  // updateActiveFilter = (ev: React.ChangeEvent<HTMLSelectElement>) => {
  updateActiveFilter = (selectedOption: string) => {
    this.setState({activeFilter: selectedOption as VehicleTypedFilters, filterOptionChanged: true}, () => {
      if (this.state.value !== '' && typeof(this.state.value) === 'string' && selectedOption !== 'none') {
        this.getVehicles()
      } else if (selectedOption === 'none') {
        const input = this.inputTypeNoneRef?.current
        if (input) {
          input.value = '' 
        }
        this.setState({value: ''}, () => {
          this.getVehicles()
        })
      }
    })
  }

  get multiSelectOptions() {
    switch(this.state.activeFilter) {
      case 'type':
        return [
          {checked: false, id: 'kick', name: 'kick', label: 'Scooter'},
          {checked: false, id: 'scooter', name: 'scooter', label: 'Moped'},
          {checked: false, id: 'car', name: 'car', label: 'Car'},
          {checked: false, id: 'bike', name: 'bike', label: 'Bike'},
        ]
      case 'deviceType':
        return [
          {checked: false, id: 0, name: 'ww', label: '2hire'},
          {checked: false, id: 1, name: 'niu', label: 'niu'},
          {checked: false, id: 3, name: 'segway', label: 'Segway'},
          {checked: false, id: 4, name: 'concox', label: 'Concox'},
          {checked: false, id: 5, name: 'omnilock', label: 'Omnilock'},
        ]
      case 'total_percentage':
        return [
          {checked: false, id: '[80,100]', name: 'full', label: 'High'},
          {checked: false, id: '[50,79]', name: 'up', label: 'Medium'},
          {checked: false, id: '[16,49]', name: 'low', label: 'Low'},
          {checked: false, id: '[0,15]', name: 'critical', label: 'Critical'},
        ]
      case 'external_status':
        return [
          {checked: false, id: 'parked', name: 'parked', label: 'Parked'},
          {checked: false, id: 'trip', name: 'trip', label: 'Trip'},
          {checked: false, id: 'paused', name: 'paused', label: 'Paused'},
          {checked: false, id: 'outside_operating_area', name: 'outsideArea', label: 'Outside Area'},
          // {checked: false, id: 'low_battery', name: 'lowBatt', label: 'Low Battery'},
          // {checked: false, id: 'repair_shop', name: 'maintenance', label: 'Maintenance'},
          // {checked: false, id: 'connection_issues', name: 'connectionIssue', label: 'Connection Issue'},
          // {checked: false, id: 'action_needed', name: 'actionNeeded', label: 'Action Needed'},
        ]
      case 'status':
        return [
          {checked: false, id: 'free', name: 'free', label: 'Free'},
          {checked: false, id: 'booked', name: 'booked', label: 'Booked'},
          {checked: false, id: 'unlocked', name: 'unlocked', label: 'Unlocked'},
          {checked: false, id: 'running', name: 'running', label: 'Running'},
          {checked: false, id: 'trunkIsOpen', name: 'trunkopen', label: 'Trunk Open'},
          {checked: false, id: 'parked', name: 'parked', label: 'Parked'},
          {checked: false, id: 'locked', name: 'locked', label: 'Locked'},
          {checked: false, id: 'unavailable', name: 'unavailable', label: 'Unavailable'},
        ]
      default:
        return []
    }
  }

  get searchInput(){
    switch (this.state.activeFilter) {
      case 'none':
      case 'reference_code':
      case 'license_plate':
      case 'brand':
      case 'model_name':
      case 'id':
      case 'currentUser':
        return (
          <SearchInput
            ref={this.inputTypeNoneRef}
            type={this.state.activeFilter === 'id' ? 'number' : 'text'}
            placeholder="Search..." 
            onChange={this.handleChange} 
            onKeyDown={this.handleKeyDown}
          />
        )
    case 'online':
      return (
        <CheckboxInputWrapper>
            <CheckboxOption
              className={typeof(this.state.value) !== 'boolean' ? 'input-active' : ''}
              onClick={() => this.handleCheckboxInputChange('none')} >
              No Option
            </CheckboxOption>
            <CheckboxOption
              className={typeof(this.state.value) === 'boolean' && this.state.value === true ? 'input-active' : ''}
              onClick={() => this.handleCheckboxInputChange(true)} >
              Online
            </CheckboxOption>
            <CheckboxOption
              className={typeof(this.state.value) === 'boolean' && this.state.value === false ? 'input-active' : ''}
              onClick={() => this.handleCheckboxInputChange(false)} >
                Offline
              </CheckboxOption>
        </CheckboxInputWrapper>
      )
    case 'type':
    case 'deviceType':
    case 'total_percentage':
    case 'external_status':
    case 'status':
      return (
        <MultiSelect
          list={this.multiSelectOptions}
          onOptionChanged={this.handleMultiSelectChange}
          dropdownButtonText={'Selected'}
          resetButtonText={'Reset'}
        />
      )
    case 'boundingBox':
      return (
        <BoundingBoxInputWrapper>
          <SearchInput
            placeholder="latitude, longitude" 
            onChange={(evt) => evt.target.validity.valid ? this.handleBoundingBoxInput('latitude', evt.target.value) : void(0)} 
            pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
            required={true}
          />
          <BoundingBoxSeparator />
          <SearchInput
            placeholder="latitude, longitude" 
            onChange={(evt) =>  evt.target.validity.valid ? this.handleBoundingBoxInput('longitude', evt.target.value) : void(0)}
            pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
            required={true}
          />
        </BoundingBoxInputWrapper>
      )
    case 'availability':
      return (
        <CustomSelect
            value={this.state.value}
            onChange={this.handleUpdateSelectFilter}
            options={availabilitySelectOptions}
          />
      )
    default:
      return (
        <SearchInput placeholder="Search..." onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
      )
    }
  }

  handleUpdateSelectFilter = (value: string) => {
    this.setState({value}, () => {
      if (this.state.value !== '') {
        this.getVehicles()
      }
    })
  }

  handleBoundingBoxInput = (input: 'latitude'|'longitude', val: string) => {
    clearTimeout(this.timer)

    const {value} = this.state
    const inputValue = val.split(',').map(n => Number(n))

    if (inputValue.length > 1) {
      const newValue:any = (value.hasOwnProperty('latitude')|| value.hasOwnProperty('longitude')) ? value : {}
      
      newValue[input] = inputValue

      this.setState({value: newValue},  () => {
        if(newValue.hasOwnProperty('latitude') && value.hasOwnProperty('longitude')) {
          this.timer = setTimeout(this.getVehicles, WAIT_INTERVAL)
        }
      })

    }
    
  }

  get handleMagnifier() {
    switch (this.state.activeFilter) {
      case 'online':
      case 'type':
      case 'deviceType':
      case 'total_percentage':
      case 'external_status':
      case 'status':
        return {display: 'none'}
      default:
        return {}
    }
  }

  handleInputMouse = () => {
    if (document !== null) {
      const element = document.getElementsByClassName('multiselect-button-dropdown')[0] as HTMLElement
      if(element) {
        const elementIsOpen = element.classList.contains('is-opened')
        if (elementIsOpen) {
          const parent = document.getElementsByClassName('multiselect-section-wrapper')[0] as HTMLElement
          if(parent) {
            parent.classList.add('deanimate')
          }
          setTimeout(() => element.click(), 500)
        }
      }
    }
  }

  render(){
    return (
      <StoreProvider>
      <Wrapper>
        <SearchInputWrapper
          onMouseLeave={this.handleInputMouse}
        >
          <MagnifierImg style={this.handleMagnifier} />
          {this.searchInput}
        </SearchInputWrapper>
        <SelectWrapper>
          <CustomSelect
            value={this.state.activeFilter}
            onChange={this.updateActiveFilter}
            options={mainSelectOptions}
          />
        </SelectWrapper>
      </Wrapper>
      </StoreProvider>
  )
  }
}

export default withAppContext<IProps>(SearchInputs)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`

const SearchInputWrapper = styled.div`
  display: flex;
  flex: 0 0 59%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  background-color: ${colors.inputBackground};
  border-radius: 0.5rem;
  height: auto;
`

const MagnifierImg = styled(Magnifier)`
  width: 1.25rem;
  height: auto;
  padding: 0 0.5rem;
`

const SearchInput = styled.input`
  background-color: ${colors.inputBackground};
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  outline: none;
  color: ${colors.inputText};
  font-family: helveticaneuelight;
  font-size: 0.9rem;
`

const SelectWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const BoundingBoxSeparator = styled.div`
  border-top: 1px solid black;
`

const BoundingBoxInputWrapper = styled.div`

  & > input:invalid {
    color: red;
  }

  & > input[value=""]:invalid {
    color: ${colors.inputText};
  }

  & > input:first-child {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > input:nth-child(2) {
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

`

const CheckboxInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  height: 2rem;

  & > input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-family: 'helveticaneuelight';
      font-size: 0.9rem;
      color: ${colors.inputText};
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
    }
  
    // Box checked
    &:checked + label:before {
      background: ${colors.checkboxSelected};
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
`

const CheckboxOption = styled.div`
  background-color: ${colors.inputActiveBackground};
  display: flex;
  lex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
  color: ${colors.textLight};
  font-family: helveticaneuelight;
  &.input-active {
    background-color: ${colors.inputActiveForeground};
  }
  &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

// const Select = styled.select`
//   background-color: ${colors.inputBackground};
//   color: ${colors.inputText};
//   font-family: helveticaneuelight;
//   font-size: 0.9rem;
//   min-height: 2rem;
//   outline: none;
//   border: 1px solid ${colors.inputBackground};
//   padding: 0 1.5rem 0 0.5rem;
//   border-radius: 0.5rem;
  
//   width: 100%;
//   min-width: 5rem;

//   appearance: none;
//   background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.inputBackground};

//   & > option {
//     background-color: ${colors.inputActiveBackground};
//     color: white;
//   }
  
//   & > option:hover {
//     background-color: ${colors.inputActiveForeground};
//   }

//   &.callForAttention {
//     animation: colorchange 1s;
//   }

//   @keyframes colorchange
//   {
//     0%   {background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.callForAttention1};}
//     25%  {background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.callForAttention2};}
//     50%  {background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.callForAttention3};}
//     75%  {background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.callForAttention4};}
//     100% {background: url(${Caret}) calc(100% - 0.5rem) / 1rem no-repeat ${colors.callForAttention5};}
//   }
// `
