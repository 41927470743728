import React from 'react'
import styled from 'styled-components'
import {AppContext} from '../AppContext'
import { VehicleTypedFilters } from '../adapters/MapAdapter'
import {ReactComponent as RefreshIcon} from '../assets/refresh.svg'
import {useDebounce} from '@react-hook/debounce'

interface IProps {
  refreshVehicles: (id?: number) => void
  requestFilterVehicle: (filterKey: VehicleTypedFilters, filterValue: string)=> void
}

const Map = ({requestFilterVehicle, refreshVehicles}: IProps) => {
  const {state} = React.useContext(AppContext)
  const [searchText, setSearchText] = useDebounce<string|null>(null, 800);
  const {activeSite} = state

  const handleRefresh = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    refreshVehicles(activeSite?.id)
  }

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.target.value)
  }

  React.useEffect(() => {
    if (searchText !== null) {  // use !==null to avoid falsy on empty string
      requestFilterVehicle('license_plate', searchText)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <MapWrapper className="hide-lg hide-xl">
      <RefreshColumn />
      <InputColumn>
        <InputWrapper>
          <Input placeholder="Filter by License Plate..." onChange={handleInputChange} />
        </InputWrapper>
      </InputColumn>
      <RefreshColumn>
        <RefreshWrapper onClick={handleRefresh}>
          <RefreshIcon />
        </RefreshWrapper>
      </RefreshColumn>
    </MapWrapper>
  )
}

export default Map

const MapWrapper = styled.div`
  position: fixed; // position absolute not working Android mobile footer
  bottom: 1rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 80;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const InputColumn = styled.div`
  display: flex;
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
`

const InputWrapper = styled.div`
  display: flex;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border: 0;
  width: 100%;
`

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid white;
  height: 14px;
  padding: 15px 12px;
  font-size: 12px;
  width: 100%;
  appearance: none;

  &:focus {
    outline-width: 0;
    outline: none;
  }

  &::placeholder {
    color: #ACACAC;
  }

`

const RefreshColumn = styled.div`
  display: flex;
  flex: 0 0 25%;
  align-items: center;
  justify-content: center;
`

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  height: 44px;
  width: 44px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
`
