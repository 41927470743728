
export default class RequestHeaders {
    static shared = new RequestHeaders()

    accept: string
    contentType: string
    authorization: string
    serviceToken: string

    private constructor() {
        this.accept = 'application/json'
        this.contentType = 'application/json'
        this.authorization = 'Bearer '
        this.serviceToken = ''
    }

    getHeaders() {
        return {
            'Accept': this.accept,
            'Content-type': this.contentType,
            'Authorization': this.authorization,
            'X-SERVICE-TOKEN': this.serviceToken,
        }
    }
}
