import React, {useEffect, useCallback, useContext, useState} from 'react'
import styled from 'styled-components'
import LayoutRow from './LayoutRow'
import MenuItem from './MenuItem'
import logo from '../assets/logo.svg'
import SiteDropDown from './MenuDropDown'
import SiteMenuItem from './SiteMenuItem'
import {respondTo} from '../helpers'
import MapFilters from './MapFilters'
import {AppContext} from '../AppContext'
import Site from '../model/Site'
import MapAdapter from '../adapters/MapAdapter'
import {isMobileDevice} from '../helpers'

const Menu = () => {
  const {state, dispatch} = useContext(AppContext)
  const {activeSite, sites} = state

  const [returnUrl, setReturnUrl] = React.useState('#')
  const [isLoadingSites, setIsLoadingSites] = useState(false)

  const updateSites = useCallback(()=> {
    setIsLoadingSites(true)
    MapAdapter.getSites().then(sites => {
      dispatch({
        type: 'UPDATE_SITES',
        payload: sites,
      })
      setIsLoadingSites(false)
    })
  }, [dispatch])

  const setActiveSite = useCallback((site: Site|null) => {
      dispatch({
        type: 'SET_ACTIVE_SITE',
        payload: site || -2
      })
    }, [dispatch])

  useEffect(() => {
    if (sites?.length === 0 && !isLoadingSites) {
      updateSites()
    }
    if (returnUrl === undefined) {
      const storageUrl = localStorage.getItem('returnUrl')
      setReturnUrl(storageUrl !== null ? storageUrl : '#')
    }
  }, [isLoadingSites, returnUrl, sites, updateSites])

  const RenderSites = React.useMemo(() => {
    const sitesCollection = sites?.map(site => (
      <SiteMenuItem
        key={`${site.id}`}
        onClick={setActiveSite}
        site={site}
        active={
          activeSite?.id === site.id
        }
      />
    ))

    // add All Sites option
    sitesCollection.push(
      <SiteMenuItem
        key={'all-sites'}
        onClick={setActiveSite}
        site={null}
        active={!activeSite?.name}
      />
    )
    return sitesCollection
  }, [setActiveSite, activeSite, sites])

  const logOutIfNoReturnUrl = useCallback(() => {
    if (returnUrl === '#') {
      logoutAction()
    }
  }, [returnUrl])


  const logOutIfIsMobile = () => {
    if (isMobileDevice()) {
      // eslint-disable-next-line no-restricted-globals
      const result = confirm('Are you sure you want to log out?');
      if (result) {
        logoutAction()
      }
    }
  }
  
  const logoutAction = () => {
    localStorage.clear()
    document.location.reload()
  }

  return (
    <LayoutRow>
      <MenuWrapper>
        <FiltersWrapper>
          <SiteDropDown
            activeTitle="filters"
            alignDropdown="left"
            ChildrenWrapper={FiltersMenuItemWrapper}
            children={MapFilters()}
            maxHeight={50}
            stayOpenWhenSelected={true}
          />
        </FiltersWrapper>
        <MainContent>
          <MainLogo id="logo" src={logo} alt="logo" onClick={logOutIfIsMobile} />
          <MenuItem link="#" active={true} className="hide-xs hide-sm">
            Operations
          </MenuItem>
          <MenuItem link={returnUrl} className="hide-xs hide-sm" action={logOutIfNoReturnUrl}>
            {returnUrl === '#' ? 'Log out' : 'Back to dashboard'}
          </MenuItem>
        </MainContent>
        <RightContent>
          <SiteDropDown
            activeTitle={
              activeSite?.name || 'All cities'
            }
            alignDropdown="right"
            ChildrenWrapper={SitesMenuItemWrapper}
            maxHeight={30}>
            {RenderSites}
          </SiteDropDown>
        </RightContent>
      </MenuWrapper>
    </LayoutRow>
  )
}

export default Menu

const FiltersWrapper = styled.div`
  display: flex;
  order: 1;
  width: 40%;
  align-items: flex-start;

  ${respondTo.md`
    display: none;
  `}
`

const FiltersMenuItemWrapper = styled.div`
  display: flex;
  flex: 0 0 44%;
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-start;

  ${respondTo.sm`
      flex: 0 0 30%;
  `}
`

const SitesMenuItemWrapper = styled.div`
  display: flex;
  flex: 0 0 calc(33.33% - 1rem);
  height: 3rem;
  flex-direction: row;
  padding: 0 0 0 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
`

const MainLogo = styled.img`
  width: 2.5rem;
  height: auto;
  padding: 0;

  ${respondTo.md`
     padding: 0 2.5rem 0 0;
     width: 3.5rem;
  `}
`

const MenuWrapper = styled.nav`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  height: 12vh;

  ${respondTo.xs`
    order: 1;
    padding: 1.5rem 0;
    height: 3rem;
  `}

  ${respondTo.md`
    height: auto;
  `}
`

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: flex-row;
  flex: 1 1 auto;
`

const MainContent = styled(ContentColumn)`
  flex: 1 1 auto;
  order: 2;
  justify-content: center;

  ${respondTo.md`
    order: 1;
    width: 70%;
    justify-content: flex-start;
  `}
`

const RightContent = styled(ContentColumn)`
  justify-content: flex-end;
  flex: 1 1 auto;
  order: 3;
  width: 40%;
  flex-wrap: nowrap;
`
