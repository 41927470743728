import { codable } from './Codable'
import ModelError from './ModelError'

@codable
export default class ActionResponse {

  constructor(
    public send: boolean
  ) { }

  static decode(data: any) {
    const { send } = data
    if (typeof(send) !== 'boolean') { throw new ModelError('send not found while decoding ActionResponse!')}

    return new ActionResponse( send )
  }
}
