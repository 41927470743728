import React, {useCallback} from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {respondTo, openMaps} from '../helpers'
import {ReactComponent as CloseIcon} from '../assets/error.svg'
import VehicleStateLabel from './VehicleStateLabel'
import DetailButton from './DetailButton'
import VehicleActions from './VehicleActions'
import VehicleMaintenanceActions from './VehicleMaintenanceActions'
import Vehicle from '../model/Vehicle'
import {AppContext} from '../AppContext'
import moment from 'moment-timezone'

enum MenuStates {
  CLOSED = '0vh',
  HALFOPEN = '42vh',
  FULLOPEN = 'auto',
}

interface WrapperProps {
  activeHeight: string
}

interface DetailTextProps {
  big?: boolean
}

interface ITouch {
  clientX: number | null
  clientY: number | null
}

interface IProps {
  userPosition: UserPosition
}

interface DetailTextProps {
  title: string
  value: string
}

const DetailText: React.FC<DetailTextProps> = ({title, value}) => {
  return (
    <DetailTextRow>
      <DetailTextColumn>
        <DetailTextTitle>{title}</DetailTextTitle>
      </DetailTextColumn>
      <DetailTextColumn>
        <DetailTextValue>{value}</DetailTextValue>
      </DetailTextColumn>
    </DetailTextRow>
  )
}

const MobileFooterMenu = ({userPosition}: IProps) => {
  const {state, dispatch} = React.useContext(AppContext)

  const {activeMarker: vehicle } = state
  const {id} = vehicle || {id: 0}

  const [initialTouchValues, setInitialTouchValues] = React.useState<ITouch>({
    clientX: null,
    clientY: null,
  })
  const [activeHeight, setActiveHeight] = React.useState(MenuStates.CLOSED)
  const [prevVehicleId, setPrevVehicleId] = React.useState(id)

  const updateMarker = useCallback((payload: Vehicle | null) => {
    dispatch({
      type: 'UPDATE_MARKER',
      payload: payload,
    })
  }, [dispatch])

  const handleSwipeUp = () => {
    switch (activeHeight) {
      case MenuStates.CLOSED:
        if (vehicle) {
          setActiveHeight(MenuStates.HALFOPEN)
        }
        break
      case MenuStates.HALFOPEN:
        setActiveHeight(MenuStates.FULLOPEN)
        break
    }
  }

  const handleSwipeDown = () => {
    switch (activeHeight) {
      case MenuStates.HALFOPEN:
        handleClose()
        break
      case MenuStates.FULLOPEN:
        setActiveHeight(MenuStates.HALFOPEN)
        break
    }
  }

  const handleClose = () => {
    setActiveHeight(MenuStates.CLOSED)
    setTimeout(() => {
      updateMarker(null)
    }, 500) // animation time is 0.5s
  }

  const handleToggle = () => {
    if (activeHeight === MenuStates.HALFOPEN) {
      handleSwipeUp()
    } else {
      handleSwipeDown()
    }
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const {clientX, clientY} = e.touches[0];

    setInitialTouchValues({clientX, clientY})
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const {clientX, clientY} = initialTouchValues
    if (clientX === null) {
      return;
    }
   
    if (clientY === null) {
      return;
    }
   
    var currentX = e.touches[0].clientX;
    var currentY = e.touches[0].clientY;
   
    var diffX = clientX - currentX;
    var diffY = clientY - currentY;
   
    if (Math.abs(diffX) < Math.abs(diffY)) {
      // sliding vertically
      if (diffY > 0) {
        // swiped up
        handleSwipeUp()
      } else {
        // swiped down
        handleSwipeDown()
      }  
    }
   
    setInitialTouchValues({
      clientX: null,
      clientY: null
    })
     
    // e.preventDefault();
  }

  const updateHeight = useCallback(() => {
    if (!id) {
      setActiveHeight(MenuStates.CLOSED)
    } else if (id && activeHeight === MenuStates.CLOSED) {
      setActiveHeight(MenuStates.HALFOPEN)
    }
    setPrevVehicleId(id)
  }, [id, activeHeight])

  React.useEffect(() => {
    if (prevVehicleId !== id) {
      updateHeight()
    }
  }, [updateHeight, prevVehicleId, id])

  return (
    <MobileWrapper style={{
      transform: `translateY(${activeHeight === MenuStates.FULLOPEN ? '0%' : activeHeight === MenuStates.HALFOPEN ? '50%' : '100%'}`,
    }} activeHeight={activeHeight} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <Content activeHeight={activeHeight}>
          <>
            <MobileDetailRow>
              <CloseIconWrapper />
              <MobileColumn>
                <HandleWrapper>
                  <Handle onClick={handleToggle} />
                </HandleWrapper>
              </MobileColumn>
              <MobileColumn>
                <CloseIconWrapper>
                  <CloseIconBtn onClick={handleClose}>
                    <CloseIcon />
                  </CloseIconBtn>
                </CloseIconWrapper>
              </MobileColumn>
            </MobileDetailRow>
            <MobileDetailRow>
              <VehicleTitleWrapper>
                <VehicleTitle onClick={handleToggle}>
                  {vehicle?.referenceCode}
                </VehicleTitle>
              </VehicleTitleWrapper>
              <VehicleLabelWrapper>
                <VehicleStateLabel vehicle={vehicle} />
              </VehicleLabelWrapper>
            </MobileDetailRow>
            <MobileDetailRow>
              <DetailTextWrapper>
                <DetailText title="Vehicle type" value={`${vehicle?.type === 'scooter' ? 'Moped' : vehicle?.type}`} />
                <DetailText title="Vehicle ID" value={`${vehicle?.id}`} />
                <DetailText title="Autonomy" value={`${vehicle?.totalPercentage}%`} />
                <DetailText title="Last update" value={moment.utc(vehicle?.fixtaken).tz(moment.tz.guess()).format('D.MM.YYYY [-] HH:mm')} />
                {/* <DetailText big={true}>General info</DetailText> */}
                {/* <DetailText>VID: {vehicle?.id}</DetailText> */}
                {/* <DetailText>Status: {vehicle?.status}</DetailText> */}
                {/* <DetailText>Autonomy: {vehicle?.totalPercentage && Math.trunc(vehicle?.totalPercentage)}%</DetailText>
                <DetailText>Reg. Date: {moment.utc(vehicle?.registrationDate  as any).tz(moment.tz.guess()).format('D.MM.YYYY')}</DetailText>
                <DetailText>Last GPS Update: {moment.utc(vehicle?.fixtaken).tz(moment.tz.guess()).format('D.MM.YY HH:mm')}</DetailText> */}
              </DetailTextWrapper>
              {/* <MobileColumn>
                <VehicleStatusWrapper>
                  <VehicleStateIcons vehicle={vehicle} />
                </VehicleStatusWrapper>
              </MobileColumn> */}
            </MobileDetailRow>
            <MobileDetailRow>
              <DetailButton
                title="directions >"
                onClick={() =>
                  openMaps(
                    userPosition,
                    {lat: vehicle?.latitude || 0, lng: vehicle?.longitude || 0},
                  )
                }
                bgColor={colors.buttonDirections}
              />
            </MobileDetailRow>
            <MobileDetailRow>
              <VehicleActionsWrapper>
                <VehicleActions vehicle={vehicle} />
              </VehicleActionsWrapper>
            </MobileDetailRow>
            <MobileDetailRow>
              <VehicleMaintenanceActionsWrapper>
                <VehicleMaintenanceActions 
                  userPosition={userPosition}
                  vehicle={vehicle} 
                />
              </VehicleMaintenanceActionsWrapper>
            </MobileDetailRow>
          </>
      </Content>
    </MobileWrapper>
  )
}

export default MobileFooterMenu

const MobileWrapper = styled.div<WrapperProps>`
  background: transparent;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  transition: height 0.5s ease, transform 0.5s ease;
  padding: 3vh 0 0 0;
  z-index: 90;

  ${respondTo.md`
    display: none;
  `}
`
const Content = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  background: ${colors.panelBackground};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0.75rem;

  ${respondTo.sm`
    padding: 1rem;
  `}
`

const Handle = styled.div`
  background: ${colors.handle};
  width: 2rem;
  width: 2rem;
  height: 0.4rem;
  border-radius: 1rem;
`

// const DetailText = styled.div<DetailTextProps>`
//   display: flex;
//   flex-direction: row;
//   font-family: helveticaneuelight;
//   font-size: 0.8rem;
//   color: ${props => (props.big ? colors.textLightTitle : colors.textDefault)};
//   padding-bottom: ${props => props.big ? '0.25rem' : '0.3rem'};
// `

const MobileDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 0 0.5rem 0;

  ${respondTo.xs`
    padding: 0.2rem 0;
  `}
`

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    flex: 0 0 35%;
  }
  &:nth-child(2) {
    flex: 1 0 auto;
  }
`

const VehicleTitleWrapper = styled.div`
  flex: 0 0 calc(80% - 1rem);
  padding: 0 0 0 1rem;
`

const VehicleTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 2rem;

  & > svg {
    height: 1rem;
    width: auto;
    padding: 0 0.5rem;
  }
`

const CloseIconWrapper = styled.div`
  display: flex;
  width: 40px;
  justify-content: flex-end;
  padding: 0 0.25rem;
`

const CloseIconBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mobileCloseBtn};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1rem;
  & > svg {
    height: 0.5rem;
    width: auto;
    & path {
      fill: ${colors.inputIcon};
    }
  }
`

const VehicleActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`

const VehicleMaintenanceActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  ${respondTo.smAndroid`
    justify-content: center;
  `}
`

const VehicleLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc(20% - 1rem);
  padding: 0 1rem 0 0;
`

const HandleWrapper = styled.div`
  display: flex;
  flex: 1 0 40%;
  justify-content: center;
  align-items: flex-start;
  // padding: 0 0.25rem;
`

const DetailTextRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  align-items:centert;
  justify-content: flex-start;
`

const DetailTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  color: #2A2A2A;
  &:first-child {
    width: 35%;
  }
`

const DetailTextTitle = styled.div`
  font-weight: 300;
  font-family: Helvetica Neue;
`

const DetailTextValue = styled.div`
  font-family: Helvetica Neue;
  font-weight: bold;
  text-transform: capitalize;
`

const DetailTextWrapper = styled.div`
  width: 100%;
`