import React from 'react'
import colors from '../colors'
import Select from 'react-select'

type SelectValues = {
  label: string
  value: any
}

interface IProps {
  value: string
  onChange: (val: any) => void
  options: SelectValues[]
}

const customStyles = {
  valueContainer:  () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
    width: '80%',
    color: colors.inputText
  }),
  placeholder:  () => ({
    fontFamily: 'helveticaneuelight',
    fontSize: '0.9rem',
  }),
  indicatorsContainer:  () => ({
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    '& > div': {
      padding: 6
    }
  }),
  menu:() => ({
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuList:() => ({
    position: 'absolute',
    backgroundColor: colors.inputActiveBackground,
    borderRadius: '0.5rem',
    padding: '0.5rem 0',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.9rem',
    padding: '0.25rem 1.5rem 0.25rem 1rem',
    fontFamily: 'helveticaneuelight',
    color: colors.textLight,
    backgroundColor: colors.inputActiveBackground,
    '&:hover': {
      backgroundColor: colors.inputActiveForeground,
    },
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    backgroundColor: colors.inputBackground,
    display: 'flex',
    flexDirection: 'row',
    width: '8rem',
    minHeight: '2rem',
    minWidth: '5rem',
    padding: '0',
    borderRadius: '0.5rem',
    '&:hover': {
      cursor: 'pointer'
    }
  }),
}

const CustomSelect = ({value, onChange, options}: IProps) => {
  const [inputLabel, setInputLabel] = React.useState('Select...')

  const handleChange = (selectedOption:any) => {
    const {value, label} = selectedOption
    setInputLabel(label)
    onChange(value)
  }

  return (
    <Select
      value={value as any}
      onChange={handleChange as any}
      options={options as any}
      styles={customStyles as any}
      isSearchable={false}
      placeholder={inputLabel}
    />
  )
}

export default CustomSelect

