import React from 'react';
import styled from 'styled-components'
import {AlertComponentProps, AlertCustomOptions} from 'react-alert'

interface BtnActions {
    onClick: () => void
    color?: string
    text?: string
}

interface CustomOptions extends AlertCustomOptions {
    title?: string
    closeText?: string
    closeTextColor?: string
    actions?: BtnActions[]
}

interface Props extends AlertComponentProps {
    options: CustomOptions
}

const AlertDialog: React.FC<Props> = ({ close, message, options }) => {
  return (
    <DialogWrapper>
      <Dialog>
        <DialogTitle>
            {options.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color={options?.closeTextColor || 'primary'}>
            {options.closeText || "Okay"}
          </Button>
          {options.actions &&
            options.actions.map((action, index) => (
              <Button
                onClick={() => {
                  action.onClick();
                  close();
                }}
                color={action?.color}
                key={`${index}`}
              >
                {action.text}
              </Button>
            ))}
        </DialogActions>
      </Dialog>
    </DialogWrapper>
  );
};

export default AlertDialog;

const DialogWrapper = styled.div`
  background: rgba(0,0,0,0.3);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: all;
`

const Dialog = styled.div`
  background: white;
  border-radius: 10px;
  max-width: 15rem;
  min-width: 15rem;
  min-height: 10rem;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const DialogTitle = styled.div`
  display: flex;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  font-size: 1.3rem;
  color: #3F3F3F; 
`

const DialogContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  padding: 10px;
  font-size: 0.9rem;
`

const DialogContentText = styled.div`
    display: flex;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 12px;
`

const DialogActions = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
`
const Button = styled.button<any>`
  flex: 1;
  border: 0;
  background-color: #0287EE;
  color: white;
  padding: 10px;

  height: 36px;
  border-radius: 8px;


  color: ${props => props.color === 'primary' ? 'white' : '#EC2626'};

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-right: none;
    border-bottom-right-radius: 10px;
  }
`
