import React from 'react'
import styled from 'styled-components'
import {respondTo} from '../helpers'
import Panel from './Panel'
import VehicleDetail from './VehicleDetail'

interface MenuProps {
  open: number // use numbers. Boolean type is causing react errors w/styled components https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
}

interface IProps extends MenuProps {
  closePanel: () => void
  openPanel: () => void
  userPosition: UserPosition
}

const Aside = ({open, closePanel, openPanel, userPosition}: IProps) => {
  const panelOpen = open ? 1 : 0

  return (
    <Wrapper open={panelOpen}>
      <Panel
        panelState={panelOpen}
        closePanel={closePanel}
        openPanel={openPanel}
      />
      <VehicleDetail userPosition={userPosition}/>
    </Wrapper>
  )
}

export default React.memo(Aside)

const Wrapper = styled.aside<MenuProps>`
  display: none;
  flex-direction: row;
  padding: 0;

  position: absolute;
  right: 0;
  top: 0;

  transition: width 0.5s linear, height 0.5s linear, transform 0.5s linear;
  transform: translateX(21rem);

  width: 25rem;

  height: 80vh;
  overflow: hidden;

  ${respondTo.md`
    display: block; 
    transform: translateX(${(props: MenuProps) => (props.open ? '0' : '21rem')});
  `}
`
