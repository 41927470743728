import React from 'react'
import styled from 'styled-components'
import {ReactComponent as IconLoading} from '../assets/loading.svg'

interface Props {
    style?: React.CSSProperties
}

const LoadingIcon: React.FC<Props> = ({style}) => {

  return (
    <LoadingIconAnimated style={style} />
  )
}

export default LoadingIcon

const LoadingIconAnimated = styled(IconLoading)`
  transform-origin: center center;
  animation: spin 2s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
