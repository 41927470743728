import { logError } from '../helpers'

export interface Codable<T> {
    new(...args: any[]): T
    decode(data: any): T
}

export function codable<T>(constructor: Codable<T>) { return constructor }

type SingleObjectDecoder<T> = (data: T, constr: Codable<T>) => T
type MultipleObjectDecoder<T> = (data: T[], constr: Codable<T>) => T[]
export type DecoderFunction<T> = SingleObjectDecoder<T> | MultipleObjectDecoder<T>

export function arrayFilterDecoder<T>(data: T[], decoder: Codable<T>): T[] {
    if (typeof data.length === 'undefined') { throw  Error('Data was not an array') }
    return data.reduce((prev: T[], current: T) => {
        try {
            const result = decoder.decode(current)
            prev.push(result)
        } catch (e) {
            // TODO: Implement error logging
            logError(e)
        }
        return prev
    }, [])
}
export function arrayFilterDecoderWithQuery<T>(codedData: any, decoder: Codable<T>): T[] {
    if (!codedData.hasOwnProperty('count') || !codedData.hasOwnProperty('data')) {
        logError(new Error('Data was not a query like response'))
        return arrayFilterDecoder(codedData, decoder)
    }
    return arrayFilterDecoder(codedData.data, decoder)
}
export function arrayThrowDecoder<T>(data: any, decoder: Codable<T>): T[] {
    if (typeof data.length === 'undefined') { throw  Error('Data was not an array') }
    return data.map((elem: any) => decoder.decode(elem))
}
