import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
// import {ReactComponent as LoadingIcon} from '../assets/loading.svg'
import {ReactComponent as SuccessIcon} from '../assets/success.svg'
import {ReactComponent as ErrorIcon} from '../assets/error.svg'
import {respondTo} from '../helpers'
import LoadingIcon from './LoadingIcon'

interface IProps extends WrapperOptions {
  title: string
  subtitle?: string
  onClick: () => Promise<any>
}

interface WrapperOptions {
  bgColor?: string
  color?: string
  mobileColor?: string
  widthHalf?: boolean
  disabled?: boolean
}

interface TitleOptions {
  subtitleExists: boolean
}

enum ButtonState {
  inactive,
  loading,
  success,
  error,
}

interface IIconProp {
  isDarkColor: boolean
}

const BigButton = ({
  title,
  subtitle,
  onClick,
  bgColor,
  color,
  mobileColor,
  widthHalf,
  disabled,
}: IProps) => {
  const [btnState, setBtnState] = React.useState<ButtonState>(
    ButtonState.inactive,
  )

  const handleOnClick = () => {
    if (!disabled && btnState !== ButtonState.loading) {
      setBtnState(ButtonState.loading)

      onClick()
        .then(() => {
          setBtnState(ButtonState.success)
          return new Promise(resolve => setTimeout(() => resolve(true), 1000))
        })
        .then(() => {
          setBtnState(ButtonState.inactive)
        })
        .catch(e => {
          setBtnState(ButtonState.error)
          return new Promise(resolve =>
            setTimeout(() => {
              setBtnState(ButtonState.inactive)
              resolve(true)
            }, 2000),
          )
        })
    }
  }

  const dynamicStyle = () => {
    if (btnState === ButtonState.success) {
      return {
        backgroundColor: '#00C14B',
        color: 'white',
      }
    }
    if (btnState === ButtonState.error) {
      return {
        backgroundColor: '#d40202',
        color: 'white',
      }
    }

    return {}
  }

  return (
    <ButtonWrapper
      disabled={disabled}
      widthHalf={widthHalf}
      bgColor={bgColor}
      color={color}
      mobileColor={mobileColor}
      style={dynamicStyle()}
      onClick={handleOnClick}>
      <StateIcon isDarkColor={(btnState === ButtonState.success ? true : bgColor === '#000000' || bgColor === undefined) ? true : false}>
        {btnState === ButtonState.loading && <LoadingIcon />}
        {btnState === ButtonState.success && <SuccessIcon />}
        {btnState === ButtonState.error && <ErrorIcon />}
      </StateIcon>
      <TitleText subtitleExists={subtitle !== undefined}>{title}</TitleText>
      {subtitle && <SubtitleText>{subtitle}</SubtitleText>}
    </ButtonWrapper>
  )
}

export default BigButton

const StateIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props: IIconProp) => props.isDarkColor ? 'white' : 'black'};
  & > svg {
    width: 1.25rem;
  }
`

const ButtonWrapper = styled.div<WrapperOptions>`
  background-color: ${props => props.bgColor || colors.buttonBackgroundMobile};
  color: ${props => props.disabled ? colors.menuDisabled : props.mobileColor || colors.textLight};
  display: flex;
  flex: 0 0 ${props => (props.widthHalf ? '45%' : 'calc(100% - 0.5rem)')};
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.6rem 0.25rem;
  margin-top: 0.25rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  font-size: 0.8rem;


  ${respondTo.xs<WrapperOptions>`
    padding: 0.75rem 0.25rem;
    margin-top: 0.5rem;
  `}

  ${respondTo.smAndroid<WrapperOptions>`
    flex: 0 0 ${(props: WrapperOptions) => (props.widthHalf ? '48%' : 'calc(100% - 0.8rem)')};
  `}

  ${respondTo.md<WrapperOptions>`
    background-color: ${(props: WrapperOptions) => props.bgColor || colors.buttonBackground};
    font-size: 1rem;
    flex: 0 0 ${(props: WrapperOptions) => (props.widthHalf ? '43' : '91')}%;
    color: ${(props: WrapperOptions) => props.disabled ? colors.menuDisabled : props.color || colors.textLight};
    padding: 0.5rem 0.25rem;
  `}
`

const TitleText = styled.div`
  font-family: ${(props: TitleOptions) =>
    props.subtitleExists ? 'helveticaneuemedium' : 'helveticaneuelight'};
  text-transform: ${(props: TitleOptions) =>
    props.subtitleExists ? 'uppercase' : 'lowercase'};
`

const SubtitleText = styled.div`
  font-family: helveticaneuelight;
  text-transform: uppercase;
`
