import React from 'react'
import {ReactComponent as Caret} from '../assets/caret.svg'
import styled, {css, StyledComponentBase} from 'styled-components'
import {respondTo, isMobileDevice} from '../helpers'
import colors from '../colors'

type AlignType = 'left' | 'right'

interface IProps {
  activeTitle: string
  children: any
  alignDropdown: AlignType
  maxHeight: number
  stayOpenWhenSelected?: boolean
  ChildrenWrapper: StyledComponentBase<'div', any, any>
}

interface DropdownContentProp {
  align: AlignType
  children: number
}

interface DropDownProp {
  maxHeight: number
}

interface TextProps {
  length: number
}

const SiteDropDown = ({
  activeTitle,
  children,
  alignDropdown,
  maxHeight,
  stayOpenWhenSelected,
  ChildrenWrapper,
}: IProps) => {
  const menuRef = React.createRef<HTMLDivElement>()

  const handleOnMouseEnter = () => {
    if (!isMobileDevice()) {
      openDropdown()
    }
  }

  const handleOnMouseLeave = () => {
    if (!isMobileDevice()) {
      closeDropDown()
    }
  }

  const openDropdown = () => {
    if (document) {
      // avoid conflict with other DropdownMen
      const el = document.querySelectorAll('.active')
      el.forEach(e => e.classList.remove('active'))
    }
    if (menuRef && menuRef.current) {
      menuRef.current.classList.add('active')
      menuRef.current.classList.remove('hide-children')
    }
  }

  const closeDropDown = () => {
    if (menuRef && menuRef.current) {
      menuRef.current.classList.remove('active')
      menuRef.current.blur()
      setTimeout(() => {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.classList.contains('active')
        ) {
          menuRef.current.classList.add('hide-children')
        }
      }, 500)
    }
  }

  const handleMenuClick = () => {
    if (menuRef && menuRef.current) {
      if (menuRef.current.classList.contains('active')) {
        closeDropDown()
      } else {
        openDropdown()
      }
    }
  }

  const handleMenuItemClick = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    if (stayOpenWhenSelected) {
      return
    }
    closeDropDown()
  }

  return (
    <DropDownWrapper>
      <Dropdown
        ref={menuRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        maxHeight={maxHeight}>
        <ActiveTextWrapper onClick={handleMenuClick}>
          <ActiveText length={activeTitle.length}>{activeTitle}</ActiveText>
          <CaretImg />
        </ActiveTextWrapper>
        <DropdownContent align={alignDropdown} className="dropdown-content">
          {Array.isArray(children)
          ? children.map((child: React.ElementType, indx: number) => (
            <ChildrenWrapper key={`${indx}`} onClick={handleMenuItemClick}>
              {child}
            </ChildrenWrapper>
          ))
          :  children
        }
        </DropdownContent>
      </Dropdown>
    </DropDownWrapper>
  )
}

export default SiteDropDown

const DropDownWrapper = styled.div`
  display: flex;
  position: relative;
  float: right;
`

const CaretImg = styled(Caret)`
  width: 14px;
  height: auto;
  padding-left: 10px;
  fill: inherit;
  & > path {
    fill: inherit;
  }
`

const ActiveTextWrapper = styled.div`
  display: flex;
  text-align: right;
  overflow: hidden;
  height: 2.2rem;
  align-items: center;
  fill: ${colors.textDefault};

  .active > & {
    color: ${colors.activeLink};
    fill: ${colors.activeLink};
  }

  ${respondTo.xs`
    white-space: nowrap;
  `}

  ${respondTo.md`
    white-space: normal;
  `}
`

const ActiveText = styled.span<TextProps>`
  text-transform: uppercase;
  font-size: 1rem;

  ${respondTo.xs`
    //font-size: 1.4rem;
    font-size: ${(props:TextProps) => props.length > 8 ? '1rem' : '1.4rem'};

    // temp workaround for text over logo on mobile
    white-space: nowrap;
    max-width: 7.5rem;
    text-overflow: clip;
    overflow: hidden;
  `}
`

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &.active .dropdown-content {
    max-height: ${(prop: DropDownProp) => prop.maxHeight}vh;
    color: ${colors.activeLink};
    stroke: ${colors.activeLink};
    padding: 2vw;
    ${respondTo.md`
      display: flex;
      padding: 0.5rem;
      max-height: unset;
    `}
  }

  &.hide-children .dropdown-content {
    & > div {
      display: none !important;
    }
  }
`

const DropdownContent = styled.div`
  display: flex;
  position: absolute;
  ${(props: DropdownContentProp) =>
    props.align === 'right'
      ? css`
          right: -1rem;
        `
      : css`
          left: -1rem;
        `}
  top: calc(12vh - 0.75rem);
  width: 96vw;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0,0,0, .35);
  z-index: 1;
  flex-wrap: wrap;
  justify-content: end;
  padding: 0 2vw;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  max-height: 0;
  transition: max-height 0.5s ease-in, padding 0.5s ease-in;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${colors.mobileMenuBackground};

  /* avoid last item at the end */

  ${respondTo.xs`
    top: 3.5rem;
  `}

  ${respondTo.smAndroid`
    ${(props: DropdownContentProp) =>
      props.align === 'right'
        ? css`
            right: calc(-2.5rem + 0.15rem);
          `
        : css`
            left: calc(-2.5rem + 0.15rem);
          `}
  `}

  ${respondTo.sm`
    ${(props: DropdownContentProp) =>
      props.align === 'right'
        ? css`
            right: calc(-3rem + 0.15rem);
          `
        : css`
            left: calc(-3rem + 0.15rem);
          `}
  `}

  ${respondTo.mdMac`
    overflow-y: hidden;
    ${(props: DropdownContentProp) =>
      props.align === 'right'
        ? css`
            right: calc(-3.5rem);
          `
        : css`
            left: calc(-3.5rem);
          `}
  `}

  ${respondTo.md`
    top: unset;
    display: none;
    min-width: 20rem;
    width: auto;
    right: 0;
    justify-content: flex-start;
    padding: 0 0.5rem;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0 1px 6px rgba(0,0,0, .35);
  `}

`
