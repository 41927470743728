import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {AppContext} from '../AppContext'
import Vehicle from '../model/Vehicle'
import {getBatteryColor} from '../helpers'

interface IMapMarkerProps {
  lat: number
  lng: number
  vehicle: Vehicle
}

interface MarkerProps {
  color?: string;
  isBig?: number
}

const MapMarker = ({vehicle}: IMapMarkerProps) => {
  const {state, dispatch} = React.useContext(AppContext)

  const {activeMarker} = state
  const {id, online, totalPercentage} = vehicle

  const handleMarkerClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({
      type: 'UPDATE_MARKER',
      payload: vehicle,
    })
  }

  // const isBig = React.useMemo(() => activeMarker && activeMarker.id === id ? 1 : 0, [id, activeMarker])
  const isBig = activeMarker && activeMarker.id === id ? 1 : 0

  return (
    <Marker color={getBatteryColor(online, totalPercentage)} onClick={handleMarkerClick} isBig={isBig} />
  )
}

export default MapMarker

const Marker = styled.div`
  width: ${props => props.isBig ? '2rem' : '1rem'};
  height: ${props => props.isBig ? '2rem' : '1rem'};
  transition: width 0.5s linear, height 0.5s linear;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: ${(props: MarkerProps) => props.color || colors.markerDefault};
  border: 4px solid #fff;
  box-shadow: 0 0 0 5px ${(props: MarkerProps) => props.color || colors.markerDefault};
  z-index: ${props => props.isBig ? '100' : '10'};
  position: relative;
`
