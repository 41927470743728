import React from 'react'
import Vehicle, {
  VehicleTypes,
  UnavailableDescriptor,
  ExtraInfo,
} from '../model/Vehicle'
import colors from '../colors'
import DetailButton from './DetailButton'
import MapAdapter, {VehicleCommand} from '../adapters/MapAdapter'
import {AppContext} from '../AppContext'
import {openMaps} from '../helpers'

interface IProps {
  vehicle: Vehicle | null
  showDirections?: boolean
  userPosition: UserPosition
}

const VehicleActions = ({vehicle, showDirections, userPosition}: IProps) => {
  const {dispatch} = React.useContext(AppContext)

  const updateVehicle = async (extraInfo: ExtraInfo) => {
    return MapAdapter.updateVehicle(vehicle?.id || 0, extraInfo).then(() => {
      MapAdapter.getVehicle(vehicle?.id || 0).then(payload =>
        dispatch({
          type: 'UPDATE_MARKER',
          payload,
        }),
      )
    })
  }

  const handleCommand = async (
    command: VehicleCommand,
    type: VehicleTypes | 'vehicle',
  ) => {
    if (vehicle) {
      return MapAdapter.runVehicleCommand(type, vehicle.id, command).then(
        () => {
          MapAdapter.getVehicle(vehicle.id).then(payload =>
            dispatch({
              type: 'UPDATE_MARKER',
              payload,
            }),
          )
        },
      )
    }
  }

  const handleMaintenaceCommand = async (
    movoUnavailableDescriptor: UnavailableDescriptor,
  ) => {
    // if current state was the same set as available
    if (
      vehicle?.extraInfo?.movoUnavailableDescriptor ===
      movoUnavailableDescriptor
    ) {
      return handleCommand('available', 'vehicle')
    } else {
      const extraInfo: ExtraInfo = {movoUnavailableDescriptor}

      // if the state is already unavailable: set descriptor
      if (vehicle?.extraInfo && vehicle?.extraInfo?.movoUnavailableDescriptor) {
        return updateVehicle(extraInfo)
      } else {
        // else update to unavailable then set descriptor
        return handleCommand('unavailable', 'vehicle').then(() => {
          return updateVehicle(extraInfo)
        })
      }
    }
  }

  return (
    <>
    {
      showDirections && (
        <DetailButton
          title="directions >"
          onClick={() =>
            openMaps(
              userPosition, 
              {lat: vehicle?.latitude || 0, lng: vehicle?.longitude || 0}, 
            )
          }
          bgColor={colors.buttonDirections}
        />
      )
    }
      <DetailButton
        title={
          vehicle?.extraInfo?.movoUnavailableDescriptor ===
          UnavailableDescriptor.MAINTENANCE
            ? 'set available'
            : 'set as maintenance'
        }
        onClick={() =>
          handleMaintenaceCommand(UnavailableDescriptor.MAINTENANCE)
        }
        bgColor={colors.buttonMaintenance}
      />
      <DetailButton
        title={
          vehicle?.extraInfo?.movoUnavailableDescriptor ===
          UnavailableDescriptor.WANTED
            ? 'set available'
            : 'set as missing'
        }
        onClick={() => handleMaintenaceCommand(UnavailableDescriptor.WANTED)}
        bgColor={colors.buttonMissing}
      />
      <DetailButton
        title={
          vehicle?.extraInfo?.movoUnavailableDescriptor ===
          UnavailableDescriptor.STOLEN
            ? 'set available'
            : 'set as lost'
        }
        onClick={() => handleMaintenaceCommand(UnavailableDescriptor.STOLEN)}
        bgColor={colors.buttonLost}
      />
    </>
  )
}

export default VehicleActions
