import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import MapFilters from './MapFilters'

const MapFiltersSection = () => {
  return (
    <Wrapper>
      <TitleColumn>Filter</TitleColumn>
      <FiltersColumn>
        {MapFilters()}
      </FiltersColumn>
    </Wrapper>
  )
}

export default React.memo(MapFiltersSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.filtersBackground};
  padding: 1rem;
  max-width: 32rem;
  position: absolute;
  bottom: 2rem;
  left: 0rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`

const TitleColumn = styled.div`
  font-family: helveticaneuelight;
  padding-right: 15px;
`

const FiltersColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  /* avoid last item at the end */
  &:after {
    content: '';
    flex: auto;
  }
`
