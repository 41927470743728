import React from 'react'
import styled from 'styled-components'
import {getBatteryColor} from '../helpers'

interface IBatteryProp {
  color: string
}

interface IProps {
  online: boolean|undefined
  totalPercentage: number|undefined
}

const BatteryStatusIcon = ({online, totalPercentage}: IProps) => {
  const BatteryColor = React.useMemo(() => (
    <StatusIcon color={getBatteryColor(Boolean(online), Number(totalPercentage))} />
  ), [online, totalPercentage])

  return (
    <Wrapper>
       <IconWrapper>
        {BatteryColor}
      </IconWrapper>
      {totalPercentage !== undefined && `${Math.trunc(totalPercentage)}%`}
    </Wrapper>
  )
}

export default BatteryStatusIcon

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: helveticaneuelight;
  width: 85px;
`

const IconWrapper = styled.div`
  padding-right: 0.5rem;
`

const StatusIcon = styled.div`
  background-color: ${(props: IBatteryProp) => props.color};
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
  box-shadow: 0 2px 9px rgba(0,0,0,.25)
`
