import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import LoginAdapter from '../adapters/LoginAdapter'
import {respondTo} from '../helpers'
import LoginBackground from '../assets/login_background.png'
import { ReactComponent as Logo } from '../assets/logo.svg';
import {ReactComponent as LoadingIcon} from '../assets/loading.svg'
import {ReactComponent as SuccessIcon} from '../assets/success.svg'
import {ReactComponent as ErrorIcon} from '../assets/error.svg'

type InputValues = 'service' | 'email' |'password'

const ENTER_KEY = 13

enum ButtonState {
  inactive,
  loading,
  success,
  error,
}

interface IProps {
  checkStorage: () => void
}
  const Login = ({checkStorage}: IProps) => {

  const [btnState, setBtnState] = React.useState<ButtonState>(
    ButtonState.inactive,
  )
  const [user, setUser] = React.useState({
    service: '',
    email: '',
    password: '',
  })

  const handleLogin = () => {
    setBtnState(ButtonState.loading)
    const {service, email, password} = user
    LoginAdapter.getServiceToken(service).then(service => {
      const {domain, token : serviceToken} = service

      return LoginAdapter.authenticateUser(domain, serviceToken, email, password).then(login => {

        const {code : token, expire} = login

        localStorage.setItem('token', token)
        localStorage.setItem('serviceToken', serviceToken)
        localStorage.setItem('expire', String(expire))
        // localStorage.setItem('site', site)
        localStorage.setItem('baseUrl', `https://${domain}/v4/admin`)
        localStorage.setItem('returnUrl', '#')

        setBtnState(ButtonState.success)
        return new Promise(resolve => setTimeout(() => resolve(true), 1000))
      }).then(() => {
        checkStorage()
        setBtnState(ButtonState.inactive)
      })
      .catch(e => {
        console.error(e)
        setBtnState(ButtonState.error)
        return new Promise(resolve =>
          setTimeout(() => {
            setBtnState(ButtonState.inactive)
            resolve(true)
          }, 2000),
        )
      })
    })
    .catch(e => {
      console.error(e)
      setBtnState(ButtonState.error)
      return new Promise(resolve =>
        setTimeout(() => {
          setBtnState(ButtonState.inactive)
          resolve(true)
        }, 2000),
      )
    })

  }

  const updateValue = (element: InputValues, value: string) => {
    setUser({
      ...user,
      [element]: value
    })
  }

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    var charCode = evt.which || evt.charCode || evt.keyCode || 0;
    if (charCode=== ENTER_KEY) {
      handleLogin()
    }
  }

  return (
    <LoginPage>
      <LoginPanel>
        <PanelHeader>
          <Logo />
        </PanelHeader>
        <StyledInput placeholder="Company Name" onChange={(ev) => updateValue('service', ev.target.value)}/>
        <StyledInput placeholder="Email" type="email" onChange={(ev) => updateValue('email', ev.target.value)}/>
        <StyledInput placeholder="Password Name" type="password" onChange={(ev) => updateValue('password', ev.target.value)} onKeyPress={handleKeyPress}/>
        <StyledButton onClick={handleLogin}>
          <StateIcon>
            {btnState === ButtonState.loading && <LoadingIconAnimated />}
            {btnState === ButtonState.success && <SuccessIcon />}
            {btnState === ButtonState.error && <ErrorIcon />}
          </StateIcon>
          Login
          </StyledButton>
      </LoginPanel>
    </LoginPage>
  )
}

export default Login

const LoginPage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  // background: url(${LoginBackground}) no-repeat;
  background-size: cover;
`

const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.loginPanel};
  padding: 1rem;
  width: 80%;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  align-items: center;

  ${respondTo.smAndroid`
    padding: 2rem;
    width: auto;
    min-width: 20rem;
  `}
`

const PanelHeader = styled.div`
  padding: 2rem 0;
`

const StyledInput = styled.input`
  background-color: ${colors.inputBackground};
  border: none;
  border-radius: 0.5rem;
  width: 80%;
  padding: 0.5rem;
  outline: none;
  color: ${colors.inputText};
  font-family: helveticaneuelight;
  font-size: 0.9rem;
  margin: 0.5rem 0;
`

const StyledButton = styled.button`
  position: relative;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  width: 85%;
  height: 2rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding-top: 0.25rem;

  &:focus {
    outline: none;
  }
`

const StateIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(calc(-50% - -0.15rem));
  & > svg {
    width: 1.25rem;
    fill: ${colors.buttonBackground};
  }
`

const LoadingIconAnimated = styled(LoadingIcon)`
  transform-origin: center center;
  animation: spin 2s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
