import React from 'react'
import styled from 'styled-components'
import colors from '../colors'

const MainLoader = () => {
    return (
        <LoaderWrapper>
            <LoaderBall />
            <LoaderBall />
            <LoaderBall />
        </LoaderWrapper>
    )
}

export default MainLoader

const LoaderWrapper = styled.div`
  width: 10rem;
  height: 3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const LoaderBall = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${colors.loadingIndicator};
  position: absolute;
  animation: grow 1s ease-in-out infinite alternate;

  &:first-child{
    left:0;
    transform-origin: 100% 50%;
  }
  &:nth-child(2){
    left:50%;
    transform: translateX(-50%) scale(1);
    animation-delay: 0.33s;
  }
  &:nth-child(3){
    right:0;
    animation-delay: 0.66s;
  }

  @keyframes grow {
    to{
      transform: translateX(-50%) scale(0);
    }
  }
`