import { codable } from './Codable'
import ModelError from './ModelError'

@codable
export default class UserToken {

  constructor(
    public unlimited: boolean,
    public id: number,
    public code: string,
    public expire: number,
    public clientType: number,
    public userId: number
  ) {  }

  static decode(data: any) {
    const {unlimited, id, code, expire, clientType, UserId} = data.hasOwnProperty('token') ? data.token : data
    
    if (typeof(unlimited) !== 'boolean') { throw new ModelError('unlimited not found while decoding UserToken!')}
    if (typeof(id) !== 'number') { throw new ModelError('id not found while decoding UserToken!')}
    if (typeof(code) !== 'string') { throw new ModelError('code not found while decoding UserToken!')}
    if (typeof(expire) !== 'number') { throw new ModelError('expire not found while decoding UserToken!')}
    if (typeof(clientType) !== 'number') { throw new ModelError('clientType not found while decoding UserToken!')}
    if (typeof(UserId) !== 'number') { throw new ModelError('UserId not found while decoding UserToken!')}

    return new UserToken(unlimited, id, code, expire, clientType, UserId)
  }
}
