import React from 'react'
import styled from 'styled-components'
import {respondTo} from '../helpers'

interface IProps extends IconProps {
  title: string
  value: string
  icon: any
}

interface IconProps {
  fill: string
  background: string
}

const VehicleDetailState = ({title, value, icon, fill, background}: IProps) => {
  return (
    <DetailWrapper>
      <DetailsIcon fill={fill} background={background}>
        {icon}
      </DetailsIcon>
      <TitleText>{title}</TitleText>
      <ValueText>{value}</ValueText>
    </DetailWrapper>
  )
}

export default VehicleDetailState

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DetailsIcon = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  box-shadow: 0 0 4px 0px rgba(0,0,0,0.2);
  text-align: center;
  align-items: center;
  justify-content: center;
  fill: ${(props: IconProps) => props.fill || 'inherit'};
  color: ${(props: IconProps) => props.fill || 'inherit'};
  background-color: ${(props: IconProps) => props.background || 'inherit'};

  & > svg {
    height: 1rem;
    width: auto;
    & > path {
      fill: inherit;
    }
  }

  ${respondTo.sm`
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;

    & > svg {
      height: 1.5rem;
    }
  `}
`

const TitleText = styled.div`
  text-transform: uppercase;
  padding-top: 0.5rem;
  font-size: 0.8rem;

  ${respondTo.sm`
    font-size: 1rem;
  `}
`

const ValueText = styled.div`
  font-family: helveticaneuelight;
  text-transform: capitalize;
  font-size: 0.8rem;

  ${respondTo.sm`
    font-size: 1rem;
  `}
`
