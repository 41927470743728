import React, {useCallback} from 'react'
import styled from 'styled-components'
import GoogleMapReact, {ClickEventValue} from 'google-map-react'
import MapAdapter, {VehicleQuickFilters, VehicleTypedFilters} from '../adapters/MapAdapter'
import Vehicle, {UnavailableDescriptor} from '../model/Vehicle'
import {AppContext, FilterLocation} from '../AppContext'
import {appRefreshRate} from '../AppSettings'
import LayoutRow from './LayoutRow'
import MapFiltersSection from './MapFiltersSection'
import {getClusters} from '../clusterRenderer'
import {equals} from 'ramda'
import {respondTo, isMobileDevice} from '../helpers'
import {groupBy} from 'ramda'
import LoadingIcon from './LoadingIcon'
import MobileMapFilters from './MobileMapFilters'

interface IProps {
  panelIsOpen: number
  userPosition: UserPosition
}

// interface IState {
//   vehicles: Vehicle[]
// }

enum defaultSite {
  firstLoaded = -1,
  defaultSiteIsLoaded = -2,
}

// type Bounds = {
//   nw: {
//     lat: number
//     lng: number
//   }
//   se: {
//     lat: number
//     lng: number
//   }
// }

// filters that need client-side filtering
const localUnavailableFilters: VehicleQuickFilters[] = [
  'lost',
  'missing',
  'maintenance',
]

const Map = ({panelIsOpen, userPosition}: IProps) => {
  const {state, dispatch} = React.useContext(AppContext)
  const {activeInMapFilters, activeMarker, activeSite, sites, vehicles, currentFilters, loading} = state

  const [defaultMapProps] = React.useState({
    center: {
      lat: 41.899,
      lng: 12.502,
    },
    zoom: 11,
  })
  const [bounds, setBounds] = React.useState<number[]>([])
  const [mapZoom, setMapZoom] = React.useState(10)
  const [prevActiveSiteId, setPrevActiveSiteId] = React.useState(
    defaultSite.firstLoaded,
  )
  const [prevActiveFilters, setPrevActiveFilters] = React.useState(
    activeInMapFilters,
  )
  const [prevActiveMarkerId, setPrevActiveMarkerId] = React.useState(
    activeMarker && activeMarker.id,
  )
  const [mapRef, setMapRef] = React.useState<any>(null)

  const [lastUpdateCheck, setLastUpdateCheck] = React.useState(0)
  const [lastVehiclesUpdate, setLastVehiclesUpdate] = React.useState(0)

  const [userPositionAcquired, setUserPositionAcquired] = React.useState(false)

  const setAppLoading = useCallback((state: boolean) => {
    dispatch({
      type: 'UPDATE_LOADER',
      payload: state,
    })
  }, [dispatch])

  const applyClientSideFilters = (vehicles: Vehicle[]) => {
     // if some of the "client-side" filters are included. Do filtering.
     if (
      activeInMapFilters.length > 0 &&
      activeInMapFilters.some(f => localUnavailableFilters.includes(f))
    ) {
      const transformedFilters: UnavailableDescriptor[] = localUnavailableFilters.reduce(
        (all, curr) => {
          const isActive = activeInMapFilters.includes(curr)
          if (isActive) {
            switch (curr) {
              case 'lost':
                all.push(UnavailableDescriptor.STOLEN)
                break
              case 'missing':
                all.push(UnavailableDescriptor.WANTED)
                break
              case 'maintenance':
                all.push(UnavailableDescriptor.MAINTENANCE)
                break
            }
          }
          return all
        },
        [] as UnavailableDescriptor[],
      )

      return dispatch({
        type: 'UPDATE_VEHICLES',
        payload: vehicles.filter(v => {
          return (
            v.extraInfo &&
            transformedFilters.includes(
              v.extraInfo.movoUnavailableDescriptor,
            )
          )
        }),
      })
    }

    return dispatch({
      type: 'UPDATE_VEHICLES',
      payload: vehicles,
    })

  }

  const updateVehicles = useCallback((id?: number) => {
    setLastVehiclesUpdate(Date.now())
    return MapAdapter.getVehiclesQuickFilter(id, activeInMapFilters).then(applyClientSideFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInMapFilters, dispatch])

  const requestUpdateVehicles = useCallback((id?: number) => {
    setPrevActiveFilters(activeInMapFilters) // update prev filters

    if (currentFilters !== FilterLocation.map) {
      return
    }

    setAppLoading(true)

    updateVehicles(id)

  }, [activeInMapFilters, setAppLoading, currentFilters, updateVehicles])


  const requestFilterVehicle = (filterKey: VehicleTypedFilters, filterValue: string) => {
    setPrevActiveFilters(activeInMapFilters) // update prev filters

    if (currentFilters !== FilterLocation.map) {
      return
    }

    setAppLoading(true)

    setLastVehiclesUpdate(Date.now())
    return MapAdapter.getVehiclesQuickFilterWithExtraKeys(activeSite?.id, activeInMapFilters, filterKey, filterValue).then(applyClientSideFilters)
  }

  const updateMapCenter = useCallback((lat?: number, lng?: number) => {
    if (mapRef) {
      if (lat && lng) {
        if(isMobileDevice()) {
          const bounds = mapRef.getBounds()
          const ne = bounds.getNorthEast().lat()
          const sw = bounds.getSouthWest().lat()
          const diff = (ne - sw )/ 100
          const newLat = lat - Math.abs(diff * 20)
          mapRef.panTo({lat: newLat, lng})
        } else {
          mapRef.panTo({lat, lng})

          const currentZoom = mapRef.getZoom()
          if (currentZoom < 20) {
            mapRef.setZoom(20)
          }
        }

      } else {
        if (activeSite) {
          const {
            topLatitude,
            bottomLatitude,
            leftLongitude,
            rightLongitude,
          } = activeSite
          // if "All Sites" then values are undefined
          if (topLatitude !== undefined) {
            mapRef.fitBounds({
              north: topLatitude,
              east: rightLongitude,
              south: bottomLatitude,
              west: leftLongitude,
            })
          } else if (userPosition) {
            mapRef.panTo(userPosition)
          }
        }
      }
    }
  }, [activeSite, mapRef, userPosition])

  const locateUser = () => {
    mapRef.panTo(userPosition)
    mapRef.setZoom(15)
  }

  const checkUserLocationIsSet = React.useCallback(()=>{
    if(!userPositionAcquired && userPosition && mapRef) {
      setUserPositionAcquired(true)
      locateUser()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, userPosition, userPositionAcquired])
  React.useEffect(() => {
    checkUserLocationIsSet()
    const isNotSameSiteAsBefore =
      activeSite && activeSite.id !== prevActiveSiteId
    // first render
    if (
      sites.length > 0 &&
      vehicles.length === 0 &&
      prevActiveSiteId === defaultSite.firstLoaded
    ) {
      setPrevActiveSiteId(defaultSite.defaultSiteIsLoaded)
      requestUpdateVehicles()
    } else if (
      (activeSite !== null && isNotSameSiteAsBefore) ||
      !equals(activeInMapFilters, prevActiveFilters)
    ) {
      // if site changes or the map filters change
      if (activeSite) {
        const {id} = activeSite
        setPrevActiveSiteId(id)
        requestUpdateVehicles(id)
      } else {
        requestUpdateVehicles()
      }

      if (isNotSameSiteAsBefore) {
        updateMapCenter()
      }
    } else if (
      activeMarker &&
      activeMarker.id !== prevActiveMarkerId
    ) {
      // if there's a new active marker
      const {latitude, longitude} = activeMarker
      updateMapCenter(latitude, longitude)
      setPrevActiveMarkerId(activeMarker && activeMarker.id)
    } else if (
      activeMarker?.id !== prevActiveMarkerId && activeMarker !== null
    ) {
      // if active marker was unselected update reference
      setPrevActiveMarkerId(activeMarker && activeMarker.id)
    }
    else {
      const timeout = setTimeout(() => {
        const timePassed = lastVehiclesUpdate !== 0 ? Date.now() - lastVehiclesUpdate : 0
        if (timePassed > appRefreshRate) {
          requestUpdateVehicles(activeSite?.id)
        }
        setLastUpdateCheck(Date.now())
      }, 5000)  // 300000
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [
    updateMapCenter,
    requestUpdateVehicles,
    activeMarker,
    sites.length,
    activeInMapFilters,
    activeSite,
    vehicles,
    prevActiveSiteId,
    prevActiveMarkerId,
    prevActiveFilters,
    dispatch,
    mapRef,
    mapZoom,
    lastUpdateCheck,
    lastVehiclesUpdate,
    checkUserLocationIsSet
  ])

  const handleApiLoaded = ({map, maps}: any) => {
    setMapRef(map)
  }

  const createMapOptions = (maps: any) => {
    return {
      clickableIcons: false,
      fullscreenControl: !isMobileDevice(),
      fullscreenControlOptions: {
        position: maps.ControlPosition.BOTTOM_LEFT,
      },
      zoomControl: !isMobileDevice(),
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
      },
      gestureHandling: isMobileDevice() ? 'greedy' : 'cooperative',
    }
  }

  const handleMapClick = (e: ClickEventValue) => {
    dispatch({
      type: 'UPDATE_MARKER',
      payload: null,
    })
  }

  const handleZoomIn = (expansionZoom: number, coords: LatLng) => {
    mapRef.setZoom(expansionZoom)
    mapRef.panTo(coords)
  }

  // map vehicles to geoJson objs
  const vehicleGroups = groupBy(
    geojson => geojson.properties.vehicle.batteryClassification.replace(' ', ''),
    state.vehicles.map(vehicle => ({
      type: 'Feature',
      properties: {
        vehicle,
        cluster: false,
      },
      geometry: {
        type: 'Point',
        coordinates: [vehicle.longitude, vehicle.latitude],
      },
    })),
  )

  const clustersCallback = React.useCallback(() => {
    return Object.keys(vehicleGroups).map((key, index) => {
      return getClusters({
        key,
        points: vehicleGroups[key],
        bounds,
        zoom: mapZoom,
        onClusterClick: handleZoomIn,
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleGroups])

  return (
    // Important! Always set the container height explicitly
    <MapWrapper panelIsOpen={panelIsOpen}>
      {loading &&  (
      <LoadingIndicatorWrapper> 
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyCQq1UkJVH76-tUgUtV8kV-lpmjWv4hbPY'}}
        defaultCenter={userPosition || defaultMapProps.center}
        defaultZoom={defaultMapProps.zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        options={createMapOptions}
        onClick={handleMapClick}
        onChange={({zoom, bounds}) => {
          setMapZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
        onGoogleApiLoaded={handleApiLoaded}>
          {
            !loading && clustersCallback()
          }
        {/* {Object.keys(vehicleGroups).map((key, index) => {
          return getClusters({
            key,
            points: vehicleGroups[key],
            bounds,
            zoom: mapZoom,
            onClusterClick: handleZoomIn,
          })
        })} */}
        {userPosition && (
          <UserMarker key="user-position" lat={userPosition.lat} lng={userPosition.lng} />
        )}
      </GoogleMapReact>
      <MobileMapFilters refreshVehicles={requestUpdateVehicles} requestFilterVehicle={requestFilterVehicle} />
      <LayoutRow className="hide-xs hide-sm hide-md">
        <MapFiltersWrapper>
          <MapFiltersSection />
        </MapFiltersWrapper>
      </LayoutRow>
    </MapWrapper>
  )
}

export default Map

const MapWrapper = styled.div<Partial<IProps>>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: width 0.5s;

  ${respondTo.md`
    width: ${(props: IProps) =>
      props.panelIsOpen ? 'calc(100% - 25rem)' : '100%'};
  `}
`

const UserMarker = styled.div<any>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #3c8fef;
  border: 3px solid white;
  box-shadow: 0px 0px 0.3rem #000000;
  z-index: 10;
`

const MapFiltersWrapper = styled.div`
  position: relative;
`

const LoadingIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 25rem;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.3);
`

const LoadingIndicator = styled(LoadingIcon)`
  z-index: 100;
`