import amsterdam from './amsterdam.svg'
import barcelona from './barcelona.svg'
import berlin from './berlin.svg'
import bologna from './bologna.svg'
import madrid from './madrid.svg'
import mexicoCity from './mexico_city.svg'
import milan from './milan.svg'
import paris from './paris.svg'
import rome from './rome.svg'
import defaultPointer from './default_pointer.svg'

export const MapIcons = {
  amsterdam,
  barcelona,
  berlin,
  bologna,
  madrid,
  mexicoCity,
  milan,
  paris,
  rome,
  defaultPointer
}