import React from 'react'
import styled from 'styled-components'
import {AppContext} from '../AppContext'
import LayoutRow from './LayoutRow'
import moment from 'moment'

const LastUpdate = () => {
  const {state} = React.useContext(AppContext)
  const {lastVehiclesUpdate} = state

  const [lastUpdateCheck, setLastUpdateCheck] = React.useState(0)
  const timeString = React.useRef('Loading...')

  React.useEffect(() => {
      const timeout = setTimeout(() => {
        timeString.current = lastVehiclesUpdate 
        ? `Last update: ${moment.utc(lastVehiclesUpdate).fromNow()}` 
        : 'Loading...'
        setLastUpdateCheck(Date.now())
      }, timeString.current === 'Loading...' ? 1000 : 60000)
      return () => {
        clearTimeout(timeout)
      }
  }, [lastUpdateCheck,lastVehiclesUpdate])

  return (
    <LayoutRow className="hide-xs hide-sm hide-md">
      <LastUpdateWrapper>
        {timeString.current}
      </LastUpdateWrapper>
    </LayoutRow>
  )
}

export default LastUpdate


const LastUpdateWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 0.5rem 0rem;
font-size: 13px;
font-family: helveticaneuelight;
`;
