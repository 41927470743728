import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {StoreProvider} from './AppContext'
import AlertTemplate from './components/AlertTemplate'
import { positions, Provider as AlertProvider } from 'react-alert'

const alertOptions = {
  position: positions.MIDDLE
}

ReactDOM.render(
  <StoreProvider>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <App />
    </AlertProvider>
  </StoreProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
