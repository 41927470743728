import React, {useCallback} from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {AppContext} from '../AppContext'
import Vehicle from '../model/Vehicle'
import SearchInputs from './SearchInputs'
import {ReactComponent as LoadingIcon} from '../assets/loading.svg'
import BatteryStatusIcon from './BatteryStatusIcon'

interface StyledProps {
  panelState?: number
}

interface IProps extends StyledProps {
  closePanel: () => void
  openPanel: () => void
}

const Panel = ({panelState, openPanel, closePanel}: IProps) => {
  const {state, dispatch} = React.useContext(AppContext)

  const {loading, currentFilters } = state

  const setActiveVehicle = useCallback((vehicle: Vehicle) => {
    dispatch({
      type: 'UPDATE_MARKER',
      payload: vehicle,
    })
  }, [dispatch])

  const updateVehicles = useCallback((vehicles: Vehicle[]) => {
    dispatch({
      type: 'UPDATE_VEHICLES_AND_SET_PANEL_FILTERS',
      payload: vehicles,
    })
  }, [dispatch])

  return (
    <>
    <Wrapper>
      <PanelRow panelState={panelState}>
        <Heading style={{display: panelState ? 'flex' : 'none'}}>
          Vehicles List
        </Heading>
      </PanelRow>
      <PanelRow
        panelState={panelState}
        style={{display: panelState ? 'flex' : 'none'}}
      >
        <SearchInputs 
          activeSite={state.activeSite ? state.activeSite.id : null}
          updateVehicles={updateVehicles}
          currentFilters={currentFilters}
        />
      </PanelRow>
      <PanelRow
        panelState={panelState}
        style={{display: loading ? 'none' : panelState ? 'flex' : 'none'}}>
        <VehicleList>
          <VehicleListHeader>
            <VehicleHeaderListItem>Ref. Code</VehicleHeaderListItem>
            <VehicleHeaderListItem>Autonomy</VehicleHeaderListItem>
            <VehicleHeaderListItem>Details</VehicleHeaderListItem>
          </VehicleListHeader>
          <VehicleListBody>
            {state.vehicles.map(vehicle => {
              return (
                <VehicleListRow key={`${vehicle.id}`}>
                  <VehicleBodyListItem>
                    {vehicle.referenceCode}
                  </VehicleBodyListItem>
                  <VehicleBodyListItem>
                    <BatteryWrapper>
                      <BatteryStatusIcon
                        online={vehicle?.online} 
                        totalPercentage={vehicle?.totalPercentage}
                      />
                    </BatteryWrapper>
                  </VehicleBodyListItem>
                  <VehicleBodyListItem>
                    <DetailsIcon onClick={() => setActiveVehicle(vehicle)}>
                      i
                    </DetailsIcon>
                  </VehicleBodyListItem>
                </VehicleListRow>
              )
            })}
          </VehicleListBody>
        </VehicleList>
      </PanelRow>
    </Wrapper>
    {loading && <PanelLoading><LoadingIconAnimated /></PanelLoading>}
    </>
  )
}

export default React.memo(Panel)

const PanelLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const LoadingIconAnimated = styled(LoadingIcon)`
  transform-origin: center center;
  animation: spin 2s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

const Wrapper = styled.div`
  background: transparent;
  transition: width 0.5s;
`

const PanelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  justify-content: ${(props: StyledProps) =>
    props.panelState ? 'space-evenly' : 'flex-start'};
  background: ${(props: StyledProps) =>
    props.panelState ? colors.panelBackground : 'transparent'};
`

const Heading = styled.div`
  color: ${colors.header};
  font-family: helveticaneuemedium;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.25rem;
`

const VehicleList = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`

const VehicleListHeader = styled.div`
  display: flex;
  flex-direction: row;
`

const VehicleListBody = styled.div`
  display: flex;
  flex-direction: column;
  font-family: helveticaneuelight;

  max-height: 65vh;
  overflow-y: scroll;
  /* This snippet fixes MacOS issue not showing scrollbar*/
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const VehicleListRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
`

const VehicleBodyListItem = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  align-items: center;
  font-family: helveticaneuelight;
  justify-content: center;

  padding: 0.5rem 0;

  &:first-child {
    flex-basis: 25%;
    max-width: 25%;
    justify-content: flex-start;
  }

  &:nth-child(2) {
    flex-basis: 55%;
    max-width: 55%;
  }

  &:nth-child(3) {
    flex-basis: 15%;
    max-width: 15%;
  }
`

const VehicleHeaderListItem = styled(VehicleBodyListItem)`
  font-family: helveticaneuemedium;
  text-align: center;
  justify-content: center;
`

const BatteryWrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
  font-family: helveticaneuelight;
`

const DetailsIcon = styled.div`
  background-color: ${colors.panelListIcon};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  color: white;
  font-size: 0.8rem;
  display: flex;
  cursor: pointer;
`
