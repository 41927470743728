import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import VehicleDetailState from './VehicleDetailState'
import Vehicle, {VehicleStatus} from '../model/Vehicle'
import {ReactComponent as IgnitionIcon} from '../assets/ignition.svg'
// import {ReactComponent as MopedIcon} from '../assets/moped.svg'
import {getIconType, getStateColor, respondTo} from '../helpers'

interface IProps {
  vehicle: Vehicle | null
}

interface TextProp {
  length: number
}

const VehicleStateIcons = ({vehicle}: IProps) => {
  return (
    <>
      <VehicleDetailState
        title="Ignition"
        value={vehicle?.status === VehicleStatus.running ? 'ON' : 'OFF'}
        icon={<IgnitionIcon />}
        fill={
          vehicle?.status === VehicleStatus.running
            ? colors.mobileMenuBackground
            : colors.textDefault
        }
        background={
          vehicle?.status === VehicleStatus.running
            ? colors.markerActive
            : colors.mobileMenuBackground
        }
      />
      <VehicleDetailState
        title="Type"
        value={vehicle && vehicle.type ? vehicle.type : ''}
        icon={getIconType(vehicle?.type)}
        fill={colors.textDefault}
        background={colors.mobileMenuBackground}
      />
      {/* {vehicle?.type === VehicleTypes.scooter && (
        <VehicleDetailState
          title="Trunk"
          value={true ? 'Open' : 'Closed'}
          icon={<MopedIcon />}
          fill={true ? colors.textLight : colors.textDefault}
          background={true ? colors.iconTrunkOpen : colors.mobileMenuBackground}
        />
      )} */}
      <VehicleDetailState
        title="State"
        value={vehicle && vehicle.status ? vehicle.status : ''}
        icon={
          <TextAsIcon length={vehicle?.status.length || 0}>
            {vehicle?.status.slice(0,2) || ''}
          </TextAsIcon>
        }
        fill={colors.textLight}
        background={getStateColor(vehicle?.status, vehicle?.extraInfo?.movoUnavailableDescriptor)}
      />
    </>
  )
}

export default VehicleStateIcons

const TextAsIcon = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: ${(prop: TextProp) => {
    // if (prop.length >= 6) {
    //   return '0.35'
    // }

    // free
    return '0.5'
  }}rem;

  ${respondTo.sm`
    font-size: ${(prop: TextProp) => {
      // unavailable, trunkIsOpen, unlocked
      // if (prop.length >= 8) {
      //   return '0.4'
      // }

      // running
      // if (prop.length >= 7) {
      //   return '0.5'
      // }

      // locked, parked, booked
      // if (prop.length >= 6) {
      //   return '0.5'
      // }

      // free
      return '1'
    }}rem;
  `}
`
