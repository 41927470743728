import React from 'react'
import Site from './model/Site'
import Vehicle from './model/Vehicle'
import {VehicleQuickFilters} from './adapters/MapAdapter'

export type IAppState = {
  activeSite: Site | null,
  activeInMapFilters: VehicleQuickFilters[]
  activeMarker: Vehicle | null,
  currentFilters: FilterLocation,
  sites: Site[],
  vehicles: Vehicle[]
  loading: boolean
  lastVehiclesUpdate: number
}

export enum FilterLocation {
  map = 'MAP',
  panel = 'PANEL'
}

export type IAppContext = {
  state: IAppState
  dispatch: React.Dispatch<IAppConextActions>
}

export interface IAppProps {
  appContext: IAppState
}

/**
 * Initial app state
 */
export const initialState: IAppState = {
  activeSite: null,
  activeInMapFilters: [],
  activeMarker: null,
  currentFilters: FilterLocation.map,
  sites: [],
  vehicles: [],
  loading: false,
  lastVehiclesUpdate: 0,
}

// Create a context to be shared through the App
export const AppContext = React.createContext<IAppContext>({
  state: initialState,
  dispatch: (e) => e,
})

type IAppConextActions = {
  type: 'UPDATE_SITES' | 'UPDATE_VEHICLES' | 'SET_ACTIVE_SITE' | 'UPDATE_MAP_FILTERS' | 'UPDATE_PANEL_FILTER' | 'UPDATE_MARKER' | 'UPDATE_LOADER' | 'UPDATE_VEHICLES_AND_SET_PANEL_FILTERS'
  payload: any
}

// APP REDUCERS
export function reducer(state:IAppState, action:IAppConextActions) {
  switch (action.type) {
    case 'UPDATE_SITES':
      return { ...state, sites: action.payload };
    case 'UPDATE_VEHICLES':
    return { ...state, vehicles: action.payload, loading: false, lastVehiclesUpdate: Date.now() };
    case 'UPDATE_VEHICLES_AND_SET_PANEL_FILTERS':
    return { ...state, vehicles: action.payload, loading: false, currentFilters: FilterLocation.panel, activeInMapFilters: [], lastVehiclesUpdate: Date.now() };
    case 'SET_ACTIVE_SITE':
    return { ...state, activeSite: action.payload };
    case 'UPDATE_MAP_FILTERS':
    return {...state, activeInMapFilters: action.payload, currentFilters: FilterLocation.map};
    case 'UPDATE_PANEL_FILTER':
    return {...state,};
    case 'UPDATE_MARKER':
    return {...state, activeMarker: action.payload};
    case 'UPDATE_LOADER':
    return {...state, loading: action.payload};
    default:
      return state;
  }
}

// Component which will pass the ability to read store and use reducers
export function StoreProvider(props:{children?:React.ReactChild}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
  <AppContext.Provider value={value}>
    {props.children}
  </AppContext.Provider>
  )
}

/// HOC component to wrap context in a component
export function withAppContext<
  P extends { appContext?: IAppContext },
  R = Omit<P, 'appContext'>
  >(
  Component: React.ComponentClass<P>
  ): React.FC<R> {
  return function BoundComponent(props: R) {
    return (
      <AppContext.Consumer>
        {contxt => <Component {...props as R & P} appContext={contxt} />}
      </AppContext.Consumer>
    );
  };
}
