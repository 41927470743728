import React from 'react'
import styled from 'styled-components'
import LayoutPadding from './LayoutPadding'

interface LayoutRowProps {
  children?: React.ReactNode
  className?: string
}

const LayoutRow = (props:LayoutRowProps) => {
  return (
    <LayoutRowWrapper {...props}>
      <LayoutPadding>
       {props.children}
      </LayoutPadding>
    </LayoutRowWrapper>
  )
}

const LayoutRowWrapper = styled.div`
  padding: 0px 8px;
  justify-content: center;
  display: flex;
`

export default LayoutRow