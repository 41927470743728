export default class ModelError extends Error {
  code: number
  type: string
  description: string
  constructor(message: string) {
    super(message)
    this.code = 1
    this.type = 'Model Error'
    this.description = message
  }
}