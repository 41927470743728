import React from 'react'
import {css} from 'styled-components'
import {ReactComponent as ScooterIcon} from './assets/scooter.svg'
import {ReactComponent as BikeIcon} from './assets/bike.svg'
import {ReactComponent as MopedIcon} from './assets/moped.svg'
import {ReactComponent as CarIcon} from './assets/car.svg'
import {VehicleTypes, VehicleStatus} from './model/Vehicle'
import {batteryRanges} from './AppSettings'
import {UnavailableDescriptor} from './model/Vehicle'
import colors from './colors'

/**
 * Log errors to server / Log
 * @param error
 */
export function logError(error: Error) {
  // tslint:disable-next-line:no-console
  console.warn('2hire Error Logger', error)
}

/**
 * Get the corresponding icon of a vehicle type as a React component
 * @param type: VehicleTypes
 */
export const getIconType = (type?: VehicleTypes) => {
  switch (type) {
    case 'car':
      return <CarIcon />
    case 'bike':
      return <BikeIcon />
    case 'kick':
      return <ScooterIcon />
    case 'scooter':
      return <MopedIcon />
    default:
      return undefined
  }
}

/**
 * Get the assigned color for the corresponding vehicle state
 * @param status: VehicleStatus
 */
export const getStateColor = (status?: VehicleStatus, movoUnavailableDescriptor?: UnavailableDescriptor) => {
  switch (status) {
    case 'free':
      return colors.iconActive
    case 'booked':
      return colors.iconBooked
    case 'running':
      return colors.iconRunning
    case 'parked':
      return colors.iconParked
    case 'unavailable':
      if (movoUnavailableDescriptor === UnavailableDescriptor.WANTED) {
        // missing 
        return colors.iconMissing
      } else if (movoUnavailableDescriptor === UnavailableDescriptor.STOLEN) {
        // lost
        return colors.iconLost
      } else {
        // maintenance
        return colors.iconMaintenance
      }
    case 'trunkIsOpen':
      return colors.iconTrunkIsOpen
    case 'unlocked':
      return colors.iconUnLocked
    case 'locked':
      return colors.iconLocked
    default:
      return ''
  }
}

/**
 * Get the assigned color for the corresponding vehicle state
 * @param status: VehicleStatus
 */
export const getBatteryColor = (online: boolean, totalPercentage: number) => {
  const level = Math.trunc(totalPercentage)
  if (online) {
    if (level > batteryRanges.lowBattery[1]) {
      return colors.markerGoodBattery
    } else if (level > batteryRanges.lowBattery[0]) {
      return colors.markerLowBattery
    } else {
      return colors.markerCritical
    }
  } else {
    return colors.markerOffline
  }
}

export const classifyBatteryLevel = (isOnline: boolean, batteryLevel: number, ) => {
  if (isOnline) {
    const level = Math.trunc(batteryLevel)
    if (level > batteryRanges.lowBattery[1]) {
      return 'Healthy'
    } else if (level > batteryRanges.lowBattery[0]) {
      return 'Low Battery'
    } else {
      return 'Critical'
    }
  } else {
    return 'Offline'
  }
}

/**
 * Launch maps application or site
 * @param from user position coords
 * @param to destination coords
 */
export const openMaps = async (from: UserPosition, to: LatLng) => {
  if (isIosDevice()) {
    // Apple Maps
    window.open(`http://maps.apple.com/?saddr=${from?.lat},${from?.lng}&daddr=${to.lat},${to.lng}`)
    return new Promise((res, rej) => res(true))
  }

  // Google Maps
  window.open(`https://www.google.com/maps/dir/?api=1&dir_action=navigate${from && from.lng ? `&origin=${from.lat},${from.lng}` : ''}&destination=${to.lat},${to.lng}`)
  
  return new Promise((res, rej) => res(true))
}

/**
 * Interface for the breakpoints
 */
interface IBreakpoints {
  xs: string
  smAndroid: string
  sm: string
  mdMac: string
  md: string
  lg: string
  xl: string
}

/**
 * App CSS breakpoints
 */
export const breakpoints: IBreakpoints = {
  xs: '375px', // Landscape phones and down
  smAndroid: '600px', // Android Tablets
  sm: '768px', // Landscape phone to portrait tablet
  mdMac: '900px', // Portrait tablet to landscape and desktop
  md: '992px', // Portrait tablet to landscape and desktop
  lg: '1200px', // Large desktop
  xl: '1400px', // This one is new
}

/**
 * Helper to render media query CSS.
 * NOTICE this method uses min-width, a.k.a. from this breakpoint on
 */
export const respondTo = Object.keys(breakpoints).reduce((media, label) => {
  media[label] = (...args: [any]) => css`
    @media (min-width: ${breakpoints[label as keyof IBreakpoints]}) {
      ${css(...args)};
    }
  `
  return media
}, {} as any)

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  )
}

export function isIosDevice() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export const degreeToRadians = (deg: number) => {
  return (deg * Math.PI) / 180
}

export const radiansToDegrees = (rad: number) => {
  return (rad * 180) / Math.PI
}
