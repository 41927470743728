import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {getBatteryColor} from '../helpers'

interface IMapClusterProps {
  lat: number
  lng: number
  count: number
  onClick: () => void
  batteryLevel: number
  online: boolean
}

interface MarkerProps {
  color?: string;
  isBig?: number
}

const MapCluster = ({count, onClick, online, batteryLevel}: IMapClusterProps) => {
  return (
    <Cluster onClick={onClick} color={getBatteryColor(online, batteryLevel)}>
      <CountText>{count}</CountText>
    </Cluster>
  )
}

export default MapCluster

const Cluster = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  transition: width 0.5s linear, height 0.5s linear;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: ${(props: MarkerProps) => props.color || colors.markerDefault};
  border: 3px solid #fff;
  box-shadow: 0 0 0 5px ${(props: MarkerProps) => props.color || colors.markerDefault};
`

const CountText = styled.span``
