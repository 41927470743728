import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {AppContext, FilterLocation} from '../AppContext'
import {VehicleQuickFilters} from '../adapters/MapAdapter'
import {respondTo} from '../helpers'
import {without} from 'ramda'

interface IProps {
  title: string
  filterKey: VehicleQuickFilters
}

const MapFilterItem = ({title, filterKey}: IProps) => {
  const {state, dispatch} = React.useContext(AppContext)
  const {activeInMapFilters, currentFilters} = state

  const prevRef = React.useRef(false)
  const [isActive, setIsActive] = React.useState(false)

  const updateFilter = React.useCallback(() => {
    dispatch({
      type: 'UPDATE_MAP_FILTERS',
      payload: !isActive
        ? without([filterKey], activeInMapFilters)
        : [...activeInMapFilters, filterKey],
    })
  }, [dispatch, filterKey, activeInMapFilters, isActive])

  const updateState = () => {
    setIsActive(!isActive)
  }

  React.useEffect(() => {
    if (isActive !== prevRef.current) {
      prevRef.current = isActive
      updateFilter()
    } else if (isActive && currentFilters !== FilterLocation.map) {
      setIsActive(false)
    }

  }, [updateFilter, isActive, activeInMapFilters, currentFilters, filterKey])

  return (
    <Item>
      <Switch>
        <SwitchInput 
          type="checkbox" 
          onChange={updateState}
          checked={isActive} 
        />
        <SwitchSlider />
      </Switch>
      <Title>{title}</Title>
    </Item>
  )
}

export default React.memo(MapFilterItem)

const Item = styled.label`
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: flex-start;

  ${respondTo.md`
    flex: 0 0 25%;
  `}
`

const Title = styled.div`
  font-size: 1rem;
  font-family: helveticaneuelight;
  color: ${colors.filtersTitle};
  padding-left: 5px;
`

const Switch = styled.div`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;

  ${respondTo.sm`
    width: 2rem;
    height: 0.75rem;
  `}
`

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.filtersBorder};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    left: 0;
    bottom: 0;
    background-color: ${colors.filtersMarker};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + &:before {
    transform: translateX(1.5rem);
  }

  input:checked + & {
    background-color: ${colors.filtersChecked};
  }

  input:focus + & {
    box-shadow: 0 0 1px ${colors.filtersChecked};
  }

  ${respondTo.sm`
    &:before {
      height: 0.75rem;
      width: 0.75rem;
    }

    input:checked + &:before {
      transform: translateX(1.25rem);
    }
  `}
`
