import React from 'react'
import styled from 'styled-components'
import Vehicle from '../model/Vehicle'
// import {ReactComponent as IgnitionIcon} from '../assets/ignition.svg'
// import {ReactComponent as MopedIcon} from '../assets/moped.svg'
import {getStateColor} from '../helpers'

interface IProps {
  vehicle: Vehicle | null
}

interface TextProp {
    bgColor: string
}

const VehicleStateIcons = ({vehicle}: IProps) => {
    // const getBackgroundColor 
    const backgroundColor = getStateColor(vehicle?.status, vehicle?.extraInfo?.movoUnavailableDescriptor)

  return (
    <>
        <TextAsIcon bgColor={backgroundColor}>
            {vehicle?.status}
        </TextAsIcon>
    </>
  )
}

export default VehicleStateIcons

const TextAsIcon = styled.span<TextProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 12px;
  padding: 4px 20px;
  color: white;
//   min-width: 90px;
  background-color:${props => props.bgColor};
`
