import React from "react";
import styled from "styled-components";
import colors from "../colors";

interface NavItemProps extends LinkProps {
  children?: React.ReactNode;
  link: string;
  className?: string
  action?: () => void
}

interface LinkProps {
  active?: boolean;
}

const NavItem = ({className, link, active, children, action}: NavItemProps) => {
  const handleClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    if (action) {
      ev.preventDefault()
      action()
    }
  }

  return (
    <MenuItem className={className}>
      <MenuLink href={link} onClick={handleClick} active={active}>
        {children}
      </MenuLink>
    </MenuItem>
  )
}

const MenuItem = styled.div`
  padding: 0 1.5rem;
`;

const MenuLink = styled.a`
  border: none;
  color: ${(props: LinkProps) =>
    props.active ? colors.activeLink : colors.textDefault};

  &:active, &:visited: {
    color: ${(props: LinkProps) =>
      props.active ? colors.activeLink : colors.textDefault};
  }
`;

export default React.memo(NavItem);
