// import {baseUrl} from '../AppSettings'
import BaseUrl from './BaseUrl'
import RequestHeaders from './RequestHeaders'
import Vehicle, {UnavailableDescriptor, VehicleTypes, ExtraInfo} from '../model/Vehicle'
import Site from '../model/Site'
import ActionResponse from '../model/ActionResponse'
import {stringify} from 'query-string'
import serverResponseValidator from './ServerResponseValidator'
import {arrayFilterDecoder} from '../model/Codable'
import {includes} from 'ramda'
import {batteryRanges} from '../AppSettings'

export interface GetVehiclesQuery {
  mode: string
  site?: number
  filters?: string
}

export type VehicleQuickFilters =
   'type.car'
  | 'type.kick'
  | 'type.scooter'
  | 'type.bike'
  | 'availability.active'
  | 'battery.healthy'
  | 'battery.medium'
  | 'battery.low'
  | 'battery.critical'
  | 'offline'
  | 'lost'
  | 'missing'
  | 'maintenance'

export type VehicleTypedFilters = 
   'none'
  | 'reference_code'
  | 'id'
  | 'license_plate'
  | 'online'
  | 'type'
  | 'deviceType'
  | 'boundingBox'
  | 'brand'
  | 'model_name'
  | 'currentUser'
  | 'availability'
  | 'total_percentage' //battery status
  | 'external_status'
  | 'status'

type StatusType = 'free'|'booked'|'unlocked'|'running'|'trunkIsOpen'|'parked'|'locked'|'unavailable'
export interface VehicleFilters {
  _self?: {
    online?: Boolean,
    status?: StatusType[],
    type?: ('scooter'|'car'|'bike'|'kick')[], 
    total_percentage?: [number, number][],
    inMaintenance?: boolean,
    extraInfo?: {
      movoUnavailableDescriptor: UnavailableDescriptor
    },
    reference_code?: string
    id?: number
    license_plate?: string
    brand?: string
    model_name?: string
    deviceType?: number[]
    latitude?: number,  // boundingBox
    longitude?: number, // boundingBox
  },
  bookings?: {
    _self: {
      status: number[]
    }
  },
}

export type VehicleCommand = 'locate'| 'start' | 'end' | 'stop' | 'on' |'off' | 'trunk/close' | 'trunk/open' | 'available' |'unavailable' | 'saddle/open'

export default class MapAdapter {
  static async getSites() {
    const url = BaseUrl.shared.url + `/api/service/site`

    const query = {
      mode: 'minimal',
    }

    return fetch(`${url}?${stringify(query)}`, {
      method: 'GET',
      headers: RequestHeaders.shared.getHeaders(),
    })
      .then(response => response.json())
      .then(json =>
        serverResponseValidator<Site>(json, Site, arrayFilterDecoder),
      )
      .then(d => d as Site[])
      .catch(error => {
        return Promise.reject(error)
      })
  }

  static async getVehiclesQuickFilter(site?: number, filters?: VehicleQuickFilters[]) {
    const buildFilters = filters !== undefined && filters !== null && filters.length > 0 ? generateFilters(filters) : null
    return this.getVehicles(site, buildFilters)
  }

  static async getVehiclesQuickFilterWithExtraKeys(site: number|undefined, filters: VehicleQuickFilters[], key: VehicleTypedFilters, value: string) {
    let buildFilters:any = filters !== undefined && filters !== null && filters.length > 0 ? generateFilters(filters) : null

    if(value) {
      if(buildFilters) {
        if(buildFilters?._self) {
          buildFilters._self[key] = value
        }
      } else {
        buildFilters = {
          _self: { [key]: value }
        }
      }
    }

    return this.getVehicles(site, buildFilters)
  }

  static async getVehiclesTypedFilter(site?: number|null, filters?: VehicleFilters) {
    return this.getVehicles(site, filters)
  }

  static async getVehicles(site?: number|null, filters?: VehicleFilters|null) {
    const url = BaseUrl.shared.url + `/api/sharing/vehicle`
    // const url = BaseUrl.shared.url + `/api/sharing/vehicle/map`

    const query: GetVehiclesQuery = {
      mode: 'minimal',
    }

    if (site !== undefined && site !== null) {
      query.site = site
    }

    if (filters !== undefined && filters !== null) {
      query.filters = JSON.stringify(filters)
    }

    return fetch(`${url}?${stringify(query)}`, {
    // return fetch(`${url}`, {
      method: 'GET',
      headers: RequestHeaders.shared.getHeaders(),
    })
      .then(response => response.json())
      .then(json =>
        serverResponseValidator<Vehicle>(json, Vehicle, arrayFilterDecoder),
      )
      .then(d => d as Vehicle[])
      .catch(error => {
        return Promise.reject(error)
      })
  }

  static async runVehicleCommand(type: VehicleTypes | 'vehicle', id: number, command: VehicleCommand) {
    const url = BaseUrl.shared.url + `/api/maintenance/sharing/${type}/${id}/${command}`

    return fetch(url, {
      method: 'GET',
      headers: RequestHeaders.shared.getHeaders(),
    })
      .then(response => response.json())
      .then(json =>
        // TODO: Work in Progress
         serverResponseValidator<ActionResponse>(json, ActionResponse),
      )
      .then(d => d as ActionResponse)
      .catch(error => {
        return Promise.reject(error)
      })

  }


  static async updateVehicle(id: number, extraInfo:ExtraInfo ) {
    const url = BaseUrl.shared.url + `/api/sharing/vehicle/${id}`

    const params = {
      body: JSON.stringify({extraInfo}),
      headers: RequestHeaders.shared.getHeaders(),
      method: 'PUT',
    }

    return fetch(url, params)
      .then(response => response.json())
      .then(json =>
        // TODO: Work in Progress
        // updated: true
        // serverResponseValidator<Site>(json, Site, arrayFilterDecoder),
        json
      )
      // .then(d => d as Site[])
      .catch(error => {
        return Promise.reject(error)
      })
  }

  static async getVehicle(id: number) {
    const url = BaseUrl.shared.url + `/api/sharing/vehicle/${id}`

    return fetch(url, {
      method: 'GET',
      headers: RequestHeaders.shared.getHeaders(),
    })
      .then(response => response.json())
      .then(json =>
        serverResponseValidator<Vehicle>(json, Vehicle),
      )
      .then(d => d as Vehicle)
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

function generateFilters(filters: VehicleQuickFilters[]) {
  const f:VehicleFilters = {}

  const addSelfKey = () => {
    if (!f.hasOwnProperty('_self')) { f._self = {} }
  }

  const updateTypeFilter = (type: 'car' | 'bike' | 'kick' | 'scooter' ) => {
    if (filters.includes(`type.${type}` as VehicleQuickFilters)) {
      addSelfKey()

      if (f._self) {
        if (f._self.type) {
          f._self.type.push(type) 
        } else {
          f._self.type = [type]
        }
      }
    }
  }

  const updateTotalPercentageFilter = (neededPercentage:[number, number][]) => {
    addSelfKey()    
    if (f._self) {
      if (f._self.total_percentage && f._self.total_percentage.length > 0) {
        const curPercentages = f._self.total_percentage
        f._self.total_percentage.push(...neededPercentage.filter(pr => includes(pr, curPercentages)))
      } else {
        f._self.total_percentage = neededPercentage
      }
    }
  }

  const updateStatusFilter = (neededFilters:StatusType[]) => {
    addSelfKey()
    if (f._self) {
      if (f._self.status && f._self.status.length > 0) {
        const curStatus = f._self.status
        f._self.status.push(...neededFilters.filter((str) => !curStatus.includes(str)))
      } else {
        f._self.status = neededFilters
      }
    }
  }

  // Cars filter
  if (filters.includes('type.car')) { updateTypeFilter('car') }

  // Kick filter
  if (filters.includes('type.kick')) { updateTypeFilter('kick') }

  // Bike filter
  if (filters.includes('type.bike')) { updateTypeFilter('bike') }

  // Scooter filter
  if (filters.includes('type.scooter')) { updateTypeFilter('scooter') }

  // Availability filter
  if (filters.includes('availability.active')) {
    addSelfKey()
    if (f._self) {
      f._self.online = true  // set online
      f._self.inMaintenance = false // isMaintenance

      // update status
      const neededFilters:StatusType[] = ['free', 'booked', 'unlocked', 'running', 'trunkIsOpen', 'parked', 'locked']
      updateStatusFilter(neededFilters)

      // update total_percentage
      const neededPercentage:[number, number][] = [[batteryRanges.lowBattery[1], 100]]
      updateTotalPercentageFilter(neededPercentage)
    }    
  }

  // Healthy batt. filter
  if (filters.includes('battery.healthy')) {
    const neededPercentage:[number, number][] = [batteryRanges.healthyBattery]
    updateTotalPercentageFilter(neededPercentage)
  }

  // Medium batt. filter
  if (filters.includes('battery.medium')) {
    const neededPercentage:[number, number][] = [batteryRanges.mediumBattery]
    updateTotalPercentageFilter(neededPercentage)
  }

  // Low batt. filter
  if (filters.includes('battery.low')) {
    const neededPercentage:[number, number][] = [batteryRanges.lowBattery]
    updateTotalPercentageFilter(neededPercentage)
  }

  // Critical batt. filter
  if (filters.includes('battery.critical')) {
    const neededPercentage:[number, number][] = [batteryRanges.criticalBattery]
    updateTotalPercentageFilter(neededPercentage)
  }

  // Online filter
  if (filters.includes('offline')) {
    addSelfKey()
    if (f._self) {
      f._self.online = false
    }
  }

  // Maintenace filters
  if (filters.includes('lost') || filters.includes('missing') || filters.includes('maintenance')) {
    const neededFilters:StatusType[] = ['unavailable']
    updateStatusFilter(neededFilters)
  }

  return f
}