import ServerError from '../model/ServerError'
import { DecoderFunction, Codable } from '../model/Codable'

export interface IServerResponse {
    data: any
    status: boolean
    error: ServerError | null
}

const serverResponseValidator = async <T>(data: IServerResponse, type: Codable<T>, decoder?: DecoderFunction<T>) => {
    const status = data.status
    if (typeof status !== 'boolean') {
        throw new ServerError(
            'UnexpectedBehaviour',
            'Encountered unknown error',
            'ERROR_UNEXPECTED',
        )
    }
    if (status) {
        return decoder ? decoder(data.data, type) : type.decode(data.data)
    }
    const error = data.error
    let serverError: ServerError
    try {
        serverError = ServerError.decode(error)
    } catch {
        throw new ServerError(
            'UnexpectedBehaviour',
            'Encountered unknown error', 
            'ERROR_UNEXPECTED',
        )
    }
    throw serverError
}

export default serverResponseValidator
