import styled from 'styled-components'
import {respondTo} from '../helpers'

// TODO: If LayoutPadding is not being used by other components we may as well move this const to LayoutRow
const LayoutPadding = styled.div`
  padding: 0 0.5rem;
  width: 95vw;
  max-width: 1200px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  ${respondTo.smAndroid`
    width: 90%;
  `}

  ${respondTo.md`
    width: 80%;
  `}
`

export default LayoutPadding
