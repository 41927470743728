import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import {AppContext} from '../AppContext'
import VehicleDetailMetric from './VehicleDetailMetric'
import {ReactComponent as CloseIcon} from '../assets/error.svg'
import moment from 'moment-timezone'
import VehicleStateIcons from './VehicleStateIcons'
import VehicleActions from './VehicleActions'
import VehicleMaintenanceActions from './VehicleMaintenanceActions'
import BatteryStatusIcon from './BatteryStatusIcon'

interface IProps extends DetailProps {
  userPosition: UserPosition
}

// interface IBatteryProp {
//   color: string
// }

interface DetailProps {
  detailsActive?: boolean
}

const VehicleDetail = ({userPosition}: IProps) => {
  const {state, dispatch} = React.useContext(AppContext)

  const detailsActive = state.activeMarker !== null

  const vehicle = state.activeMarker

  const closeDetails = () => {
    dispatch({
      type: 'UPDATE_MARKER',
      payload: null,
    })
  }

  return (
    <DetailWrapper id="detials" detailsActive={detailsActive}>
      <PanelRow>
        <Heading>Vehicle Detail</Heading>
        <CloseIconPosition onClick={closeDetails}>
          <CloseIcon />
        </CloseIconPosition>
      </PanelRow>
      <PanelRow>
        <VehicleDetailMetric title="VID" value={vehicle?.id?.toString()} />
        <VehicleDetailMetric
          title="Autonomy"
          value={
            <VehicleBatteryWrapper>
              <BatteryStatusIcon
                online={vehicle?.online} 
                totalPercentage={vehicle?.totalPercentage}
              />
            </VehicleBatteryWrapper>
          }
        />
        <VehicleDetailMetric title="Plate" value={vehicle?.licensePlate} />
        <VehicleDetailMetric
          title="Last GPS Update"
          value={
            vehicle?.fixtaken
              ? moment.utc(vehicle?.fixtaken).tz(moment.tz.guess()).format('D.MM.YY HH:mm')
              : ''
          }
        />
        <VehicleDetailMetric title="Odometer" value={`${vehicle?.km || 0}km`} />
        <VehicleDetailMetric
          title="Reg. Date"
          value={
            vehicle?.registrationDate
              ? moment.utc(vehicle?.registrationDate).tz(moment.tz.guess()).format('D.MM.YYYY')
              : ''
          }
        />
      </PanelRow>
      <PanelRow>
        <VehicleStateIcons vehicle={vehicle} />
      </PanelRow>
      <PanelRow>
        <VehicleActions vehicle={vehicle} />
      </PanelRow>
      <PanelRow>
        <VehicleMaintenanceActions userPosition={userPosition} vehicle={vehicle} showDirections={true} />
      </PanelRow>
    </DetailWrapper>
  )
}

export default VehicleDetail

const DetailWrapper = styled.div`
  transition: transform 0.5s;
  height: 100%;
  width: 25rem;
  position: absolute;
  top: 0;
  background: white;
  transform: translate(
    ${(props: DetailProps) => (props.detailsActive ? '0' : '25rem')}
  );
  overflow-y: scroll;
`

const Heading = styled.div`
  color: ${colors.header};
  font-family: helveticaneuemedium;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.25rem;
`

const CloseIconPosition = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 0.5rem;
  fill: ${colors.textDefault};
  cursor: pointer;

  & > svg {
    height: 1rem;
    width: auto;
    & > path {
      fill: ${colors.textDefault};
    }
  }
`

const PanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-evenly;
  background: ${colors.panelBackground};
`

const VehicleBatteryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: helveticaneuelight;
`
