import React from "react";
import styled from "styled-components";
import colors from "../colors";
import LayoutRow from "./LayoutRow";
import Heading from "./Heading";
import StatisticsSquare from "./StatisticsSquare";
import {AppContext} from '../AppContext'
import {classifyBatteryLevel} from '../helpers'

const defaultStats = {
  Healthy: 0,
  'Low Battery': 0,
  Critical: 0,   // 0% to 25%
  Offline: 0,
}

const Statistics = () => {
  const {state} = React.useContext(AppContext)
  const {vehicles} = state

  const [vehicleStats, setVehicleStats] = React.useState({...defaultStats})

  const updateVehicleStats = React.useCallback(() => {
    const newStats = {...defaultStats}

    vehicles.forEach(vehicle => {
      const {totalPercentage, online} = vehicle
      // count healthy, low batt & critical
      const level = classifyBatteryLevel(online, totalPercentage)
      newStats[level] = newStats[level] + 1
      // 
      // if (!online) {
      //   newStats['Offline'] = newStats['Offline'] + 1
      // }
    })

    setVehicleStats(newStats)
  }, [vehicles])

  React.useEffect(() => {
    updateVehicleStats()
  }, [updateVehicleStats])

  return (
  <LayoutRow className="hide-xs hide-sm hide-md">
    <StatisticsWrapper>
      <HeadingWrapper>
        <Heading>Fleet Status</Heading>
      </HeadingWrapper>
      <StatisticsSquareWrapper>
        <StatisticsSquare color={colors.markerGoodBattery} value={String(vehicleStats.Healthy)} description="Healthy" />
        <StatisticsSquare color={colors.markerLowBattery} value={String(vehicleStats['Low Battery'])} description="Low battery" />
        <StatisticsSquare color={colors.markerCritical} value={String(vehicleStats.Critical)} description="Critical" />
        <StatisticsSquare color={colors.markerOffline} value={String(vehicleStats.Offline)} description="Offline" />
      </StatisticsSquareWrapper>
    </StatisticsWrapper>
  </LayoutRow>
  )
}

export default Statistics

const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const HeadingWrapper = styled.div``;

const StatisticsSquareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
`;
